import {
  Box,
  IconButton,
  Select,
  FormControl,
  InputLabel,
  Grid,
} from "@mui/material";
import { useSelector } from "react-redux";

import * as React from "react";
import { useNavigate } from "react-router-dom";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";
import styles from "./topbar.module.scss";

import { useState } from "react";
import {
  setUserCurrentSystem,
  setUserCurrentArray,
} from "../../../../Services/redux/reducers/userMenuReducer";
import { useDispatch } from "react-redux";

import Header from "../header";
const Topbar = () => {
  const userSystems = useSelector((state) => state.menu.userSystems);
  const userArrays = useSelector((state) => state.menu.userArrays);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const Profile = () => {
    navigate("/main/profile");
  };

  const Logout = () => {
    navigate("/");
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setdropDownValue(event.target.value);
    navigate("/main");
  };
  const [dropDownValue, setdropDownValue] = useState(userSystems?.[0]?.AGDESC1);

  const currentSystem = userSystems.filter(
    (system) => system.AGDESC1 === dropDownValue
  );

  const currentArray = userArrays.filter(
    (array) =>
      `${array?.[0]?.AHSYSTEMID}`.toLowerCase() ===
      `${currentSystem?.[0]?.AGSYSTEMID}`.toLowerCase()
  );

  dispatch(setUserCurrentSystem(currentSystem));
  dispatch(setUserCurrentArray(currentArray));

  return (
    <Box
      display="flex"
      width="100%"
      flexDirection="row"
      backgroundColor="#064987"
      alignItems="center"
      padding="0.2%"
    >
      <Grid container>
        <Grid item xs={3} md={2} sx={{ height: "100%" }}>
          <img alt="" className={styles.logo} src="/images/taqa_logo.png" />
        </Grid>
        <Grid
          item
          xs={6}
          md={8}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Header />
        </Grid>
        <Grid
          item
          xs={3}
          md={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Box margin="0.1%" width="100%" flexDirection="row" display="flex">
            <FormControl
              sx={{
                width: "100%",
              }}
            >
              <InputLabel>Systems</InputLabel>
              <Select
                label="Systems"
                width="100%"
                onChange={handleChange}
                value={dropDownValue ?? ""}
              >
                {userSystems?.map((menu) => (
                  <MenuItem key={menu.AGDESC1} value={menu.AGDESC1}>
                    {menu.AGDESC1}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Menu
              id="fade-menu"
              MenuListProps={{
                "aria-labelledby": "fade-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              TransitionComponent={Fade}
            >
              <MenuItem onClick={Profile}>Profile</MenuItem>
              <MenuItem onClick={Logout}>Logout</MenuItem>
            </Menu>
            <IconButton onClick={handleClick}>
              <PersonOutlinedIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Topbar;
