import * as React from "react";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Grid, Checkbox, Box, Slider } from "@mui/material";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import ConfirmationAlert from "../../../common/AlertPopUp/ConfirmationAlert";
import httpUpdate from "../../../../Services/http/httpUpdate";
import { decodeToken } from "react-jwt";
import "./accordion.css";

function valuetext(value) {
  return `${value}°C`;
}

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function CustomizedAccordions(props) {
  const [expanded, setExpanded] = React.useState(props.expanded);
  const notificationsInfo = props.notificationsInfo;

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const [systemEqId, setSystemEqId] = useState();
  const [systemIndex, setSystemIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState("");
  const [notifications, setNotifications] = useState({
    AHCAT1: null,
    AHCAT2: null,
    AHCAT3: null,
    AHCAT4: null,
    AHCAT5: null,
    AHCATEGORY1: null,
    AHCATEGORY2: null,
    AHDATEUPDATED: null,
    AHDESC1: null,
    AHDESC2: null,
    AHEQUIP1: null,
    AHITEMNB: null,
    AHEQUIP3: null,
    AHLINEID: null,
    AHPOWER: null,
    AHQTY: null,
    AHSTATUS: null,
    AHSYNC: null,
    AHSYNCTIMESTAMP: null,
    AHSYSTEMID: null,
    AHTYPE: null,
    AHUPDATEDBY: null,
    AQCAT1: null,
    AQCAT2: null,
    AQCAT3: null,
    AQCAT4: null,
    AQCAT5: null,
    AQEQUIPID: null,
    AQLINEID: null,
    AQSTATUS: null,
    AQTYPE: null,
    AQUSERID: null,
    AQVALU3: null,
    AQVALUE1: null,
    AQVALUE2: null,
    AQVALUE4: null,
    AQVALUE5: null,
  });

  const [freqNotifications, setFreqNotifications] = useState({
    AHCAT1: null,
    AHCAT2: null,
    AHCAT3: null,
    AHCAT4: null,
    AHCAT5: null,
    AHCATEGORY1: null,
    AHCATEGORY2: null,
    AHDATEUPDATED: null,
    AHDESC1: null,
    AHDESC2: null,
    AHEQUIP1: null,
    AHITEMNB: null,
    AHEQUIP3: null,
    AHLINEID: null,
    AHPOWER: null,
    AHQTY: null,
    AHSTATUS: null,
    AHSYNC: null,
    AHSYNCTIMESTAMP: null,
    AHSYSTEMID: null,
    AHTYPE: null,
    AHUPDATEDBY: null,
    AQCAT1: null,
    AQCAT2: null,
    AQCAT3: null,
    AQCAT4: null,
    AQCAT5: null,
    AQEQUIPID: null,
    AQLINEID: null,
    AQSTATUS: null,
    AQTYPE: null,
    AQUSERID: null,
    AQVALU3: null,
    AQVALUE1: null,
    AQVALUE2: null,
    AQVALUE4: null,
    AQVALUE5: null,
  });

  const [voltNotifications, setVoltNotifications] = useState({
    AHCAT1: null,
    AHCAT2: null,
    AHCAT3: null,
    AHCAT4: null,
    AHCAT5: null,
    AHCATEGORY1: null,
    AHCATEGORY2: null,
    AHDATEUPDATED: null,
    AHDESC1: null,
    AHDESC2: null,
    AHEQUIP1: null,
    AHITEMNB: null,
    AHEQUIP3: null,
    AHLINEID: null,
    AHPOWER: null,
    AHQTY: null,
    AHSTATUS: null,
    AHSYNC: null,
    AHSYNCTIMESTAMP: null,
    AHSYSTEMID: null,
    AHTYPE: null,
    AHUPDATEDBY: null,
    AQCAT1: null,
    AQCAT2: null,
    AQCAT3: null,
    AQCAT4: null,
    AQCAT5: null,
    AQEQUIPID: null,
    AQLINEID: null,
    AQSTATUS: null,
    AQTYPE: null,
    AQUSERID: null,
    AQVALU3: null,
    AQVALUE1: null,
    AQVALUE2: null,
    AQVALUE4: null,
    AQVALUE5: null,
  });

  const [loadNotifications, setLoadNotifications] = useState({
    AHCAT1: null,
    AHCAT2: null,
    AHCAT3: null,
    AHCAT4: null,
    AHCAT5: null,
    AHCATEGORY1: null,
    AHCATEGORY2: null,
    AHDATEUPDATED: null,
    AHDESC1: null,
    AHDESC2: null,
    AHEQUIP1: null,
    AHITEMNB: null,
    AHEQUIP3: null,
    AHLINEID: null,
    AHPOWER: null,
    AHQTY: null,
    AHSTATUS: null,
    AHSYNC: null,
    AHSYNCTIMESTAMP: null,
    AHSYSTEMID: null,
    AHTYPE: null,
    AHUPDATEDBY: null,
    AQCAT1: null,
    AQCAT2: null,
    AQCAT3: null,
    AQCAT4: null,
    AQCAT5: null,
    AQEQUIPID: null,
    AQLINEID: null,
    AQSTATUS: null,
    AQTYPE: null,
    AQUSERID: null,
    AQVALU3: null,
    AQVALUE1: null,
    AQVALUE2: null,
    AQVALUE4: null,
    AQVALUE5: null,
  });

  const [updatedNotifications, setUpdatedNotifications] =
    useState(notificationsInfo);

  const currentSystem = notificationsInfo?.[1]?.filter(
    (filter) => filter?.AQEQUIPID === systemEqId
  );

  const systemIsOn = currentSystem
    ?.filter((f) => f.AQTYPE === "STAT")?.[0]
    ?.AQSTATUS.trim();

  const freqIsOn = currentSystem
    ?.filter((f) => f.AQTYPE === "FREQ")?.[0]
    ?.AQSTATUS.trim();
  const freqMin = currentSystem?.filter((f) => f.AQTYPE === "FREQ")?.[0]
    ?.AQVALUE1;
  const freqMax = currentSystem?.filter((f) => f.AQTYPE === "FREQ")?.[0]
    ?.AQVALUE2;

  const voltageIsOn = currentSystem
    ?.filter((f) => f.AQTYPE === "VOLT")?.[0]
    ?.AQSTATUS.trim();
  const voltageMin = currentSystem?.filter((f) => f.AQTYPE === "VOLT")?.[0]
    ?.AQVALUE1;
  const voltageMax = currentSystem?.filter((f) => f.AQTYPE === "VOLT")?.[0]
    ?.AQVALUE2;

  const loadIsOn = currentSystem
    ?.filter((f) => f.AQTYPE === "LOAD")?.[0]
    ?.AQSTATUS.trim();
  const loadMin = currentSystem?.filter((f) => f.AQTYPE === "LOAD")?.[0]
    ?.AQVALUE1;
  const loadMax = currentSystem?.filter((f) => f.AQTYPE === "LOAD")?.[0]
    ?.AQVALUE2;

  const handleFrequencyChange = (event, newValue) => {
    setFreqNotifications({
      ...freqNotifications,
      AQVALUE1: newValue?.[0],
      AQVALUE2: newValue?.[1],
    });
    setIsSaved(false);
  };

  const handleVoltageChange = (event, newValue) => {
    setVoltNotifications({
      ...voltNotifications,
      AQVALUE1: newValue?.[0],
      AQVALUE2: newValue?.[1],
    });
    setIsSaved(false);
  };

  const handleLoadChange = (event, newValue) => {
    setLoadNotifications({
      ...loadNotifications,
      AQVALUE1: newValue?.[0],
      AQVALUE2: newValue?.[1],
    });
    setIsSaved(false);
  };

  const updateSystem = (equiId) => {
    setNotifications({
      ...notifications,
      AHCAT1: currentSystem?.[3]?.AHCAT1,
      AHCAT2: currentSystem?.[3]?.AHCAT2,
      AHCAT3: currentSystem?.[3]?.AHCAT3,
      AHCAT4: currentSystem?.[3]?.AHCAT4,
      AHCAT5: currentSystem?.[3]?.AHCAT5,
      AHCATEGORY1: currentSystem?.[3]?.AHCATEGORY1,
      AHCATEGORY2: currentSystem?.[3]?.AHCATEGORY2,
      AHDATEUPDATED: currentSystem?.[3]?.AHDATEUPDATED,
      AHDESC1: currentSystem?.[3]?.AHDESC1,
      AHDESC2: currentSystem?.[3]?.AHDESC2,
      AHEQUIP1: currentSystem?.[3]?.AHEQUIP1,
      AHITEMNB: currentSystem?.[3]?.AHITEMNB,
      AHEQUIP3: currentSystem?.[3]?.AHEQUIP3,
      AHLINEID: currentSystem?.[3]?.AHLINEID,
      AHPOWER: currentSystem?.[3]?.AHPOWER,
      AHQTY: currentSystem?.[3]?.AHQTY,
      AHSTATUS: currentSystem?.[3]?.AHSTATUS,
      AHSYNC: currentSystem?.[3]?.AHSYNC,
      AHSYNCTIMESTAMP: currentSystem?.[3]?.AHSYNCTIMESTAMP,
      AHSYSTEMID: currentSystem?.[3]?.AHSYSTEMID,
      AHTYPE: currentSystem?.[3]?.AHTYPE,
      AHUPDATEDBY: currentSystem?.[3]?.AHUPDATEDBY,
      AQCAT1: currentSystem?.[3]?.AQCAT1,
      AQCAT2: currentSystem?.[3]?.AQCAT2,
      AQCAT3: currentSystem?.[3]?.AQCAT3,
      AQCAT4: currentSystem?.[3]?.AQCAT4,
      AQCAT5: currentSystem?.[3]?.AQCAT5,
      AQEQUIPID: myDropdown?.[equiId]?.AHEQUIPID,
      AQLINEID: currentSystem?.[3]?.AQLINEID,
      AQSTATUS: systemIsOn,
      AQTYPE: "STAT",
      AQUSERID: currentSystem?.[3]?.AQUSERID,
      AQVALU3: currentSystem?.[3]?.AQVALU3,
      AQVALUE1: 0,
      AQVALUE2: 1,
      AQVALUE4: currentSystem?.[3]?.AQVALUE4,
      AQVALUE5: currentSystem?.[3]?.AQVALUE5,
    });
    setFreqNotifications({
      ...freqNotifications,
      AHCAT1: currentSystem?.[0]?.AHCAT0,
      AHCAT2: currentSystem?.[0]?.AHCAT2,
      AHCAT3: currentSystem?.[0]?.AHCAT3,
      AHCAT4: currentSystem?.[0]?.AHCAT4,
      AHCAT5: currentSystem?.[0]?.AHCAT5,
      AHCATEGORY0: currentSystem?.[0]?.AHCATEGORY0,
      AHCATEGORY2: currentSystem?.[0]?.AHCATEGORY2,
      AHDATEUPDATED: currentSystem?.[0]?.AHDATEUPDATED,
      AHDESC0: currentSystem?.[0]?.AHDESC0,
      AHDESC2: currentSystem?.[0]?.AHDESC2,
      AHEQUIP0: currentSystem?.[0]?.AHEQUIP0,
      AHITEMNB: currentSystem?.[0]?.AHITEMNB,
      AHEQUIP3: currentSystem?.[0]?.AHEQUIP3,
      AHLINEID: currentSystem?.[0]?.AHLINEID,
      AHPOWER: currentSystem?.[0]?.AHPOWER,
      AHQTY: currentSystem?.[0]?.AHQTY,
      AHSTATUS: currentSystem?.[0]?.AHSTATUS,
      AHSYNC: currentSystem?.[0]?.AHSYNC,
      AHSYNCTIMESTAMP: currentSystem?.[0]?.AHSYNCTIMESTAMP,
      AHSYSTEMID: currentSystem?.[0]?.AHSYSTEMID,
      AHTYPE: currentSystem?.[0]?.AHTYPE,
      AHUPDATEDBY: currentSystem?.[0]?.AHUPDATEDBY,
      AQCAT0: currentSystem?.[0]?.AQCAT0,
      AQCAT2: currentSystem?.[0]?.AQCAT2,
      AQCAT3: currentSystem?.[0]?.AQCAT3,
      AQCAT4: currentSystem?.[0]?.AQCAT4,
      AQCAT5: currentSystem?.[0]?.AQCAT5,
      AQEQUIPID: myDropdown?.[equiId]?.AHEQUIPID,
      AQLINEID: currentSystem?.[0]?.AQLINEID,
      AQSTATUS: freqIsOn,
      AQTYPE: "FREQ",
      AQUSERID: currentSystem?.[0]?.AQUSERID,
      AQVALU3: currentSystem?.[0]?.AQVALU3,
      AQVALUE1: freqMin,
      AQVALUE2: freqMax,
      AQVALUE4: currentSystem?.[0]?.AQVALUE4,
      AQVALUE5: currentSystem?.[0]?.AQVALUE5,
    });
    setVoltNotifications({
      ...voltNotifications,
      AHCAT1: currentSystem?.[1]?.AHCAT1,
      AHCAT2: currentSystem?.[1]?.AHCAT2,
      AHCAT3: currentSystem?.[1]?.AHCAT3,
      AHCAT4: currentSystem?.[1]?.AHCAT4,
      AHCAT5: currentSystem?.[1]?.AHCAT5,
      AHCATEGORY1: currentSystem?.[1]?.AHCATEGORY1,
      AHCATEGORY2: currentSystem?.[1]?.AHCATEGORY2,
      AHDATEUPDATED: currentSystem?.[1]?.AHDATEUPDATED,
      AHDESC1: currentSystem?.[1]?.AHDESC1,
      AHDESC2: currentSystem?.[1]?.AHDESC2,
      AHEQUIP1: currentSystem?.[1]?.AHEQUIP1,
      AHITEMNB: currentSystem?.[1]?.AHITEMNB,
      AHEQUIP3: currentSystem?.[1]?.AHEQUIP3,
      AHLINEID: currentSystem?.[1]?.AHLINEID,
      AHPOWER: currentSystem?.[1]?.AHPOWER,
      AHQTY: currentSystem?.[1]?.AHQTY,
      AHSTATUS: currentSystem?.[1]?.AHSTATUS,
      AHSYNC: currentSystem?.[1]?.AHSYNC,
      AHSYNCTIMESTAMP: currentSystem?.[1]?.AHSYNCTIMESTAMP,
      AHSYSTEMID: currentSystem?.[1]?.AHSYSTEMID,
      AHTYPE: currentSystem?.[1]?.AHTYPE,
      AHUPDATEDBY: currentSystem?.[1]?.AHUPDATEDBY,
      AQCAT1: currentSystem?.[1]?.AQCAT1,
      AQCAT2: currentSystem?.[1]?.AQCAT2,
      AQCAT3: currentSystem?.[1]?.AQCAT3,
      AQCAT4: currentSystem?.[1]?.AQCAT4,
      AQCAT5: currentSystem?.[1]?.AQCAT5,
      AQEQUIPID: myDropdown?.[equiId]?.AHEQUIPID,
      AQLINEID: currentSystem?.[1]?.AQLINEID,
      AQSTATUS: voltageIsOn,
      AQTYPE: "VOLT",
      AQUSERID: currentSystem?.[1]?.AQUSERID,
      AQVALU3: currentSystem?.[1]?.AQVALU3,
      AQVALUE1: voltageMin,
      AQVALUE2: voltageMax,
      AQVALUE4: currentSystem?.[1]?.AQVALUE4,
      AQVALUE5: currentSystem?.[1]?.AQVALUE5,
    });
    setLoadNotifications({
      ...loadNotifications,
      AHCAT1: currentSystem?.[2]?.AHCAT1,
      AHCAT2: currentSystem?.[2]?.AHCAT2,
      AHCAT3: currentSystem?.[2]?.AHCAT3,
      AHCAT4: currentSystem?.[2]?.AHCAT4,
      AHCAT5: currentSystem?.[2]?.AHCAT5,
      AHCATEGORY1: currentSystem?.[2]?.AHCATEGORY1,
      AHCATEGORY2: currentSystem?.[2]?.AHCATEGORY2,
      AHDATEUPDATED: currentSystem?.[2]?.AHDATEUPDATED,
      AHDESC1: currentSystem?.[2]?.AHDESC1,
      AHDESC2: currentSystem?.[2]?.AHDESC2,
      AHEQUIP1: currentSystem?.[2]?.AHEQUIP1,
      AHITEMNB: currentSystem?.[2]?.AHITEMNB,
      AHEQUIP3: currentSystem?.[2]?.AHEQUIP3,
      AHLINEID: currentSystem?.[2]?.AHLINEID,
      AHPOWER: currentSystem?.[2]?.AHPOWER,
      AHQTY: currentSystem?.[2]?.AHQTY,
      AHSTATUS: currentSystem?.[2]?.AHSTATUS,
      AHSYNC: currentSystem?.[2]?.AHSYNC,
      AHSYNCTIMESTAMP: currentSystem?.[2]?.AHSYNCTIMESTAMP,
      AHSYSTEMID: currentSystem?.[2]?.AHSYSTEMID,
      AHTYPE: currentSystem?.[2]?.AHTYPE,
      AHUPDATEDBY: currentSystem?.[2]?.AHUPDATEDBY,
      AQCAT1: currentSystem?.[2]?.AQCAT1,
      AQCAT2: currentSystem?.[2]?.AQCAT2,
      AQCAT3: currentSystem?.[2]?.AQCAT3,
      AQCAT4: currentSystem?.[2]?.AQCAT4,
      AQCAT5: currentSystem?.[2]?.AQCAT5,
      AQEQUIPID: myDropdown?.[equiId]?.AHEQUIPID,
      AQLINEID: currentSystem?.[2]?.AQLINEID,
      AQSTATUS: loadIsOn,
      AQTYPE: "LOAD",
      AQUSERID: currentSystem?.[2]?.AQUSERID,
      AQVALU3: currentSystem?.[2]?.AQVALU3,
      AQVALUE1: loadMin,
      AQVALUE2: loadMax,
      AQVALUE4: currentSystem?.[2]?.AQVALUE4,
      AQVALUE5: currentSystem?.[2]?.AQVALUE5,
    });
    setSystemEqId(myDropdown?.[equiId]?.AHEQUIPID);
  };

  const onBlurSystems = () => {
    setNotifications({
      ...notifications,
      AHCAT1: currentSystem?.[3]?.AHCAT1,
      AHCAT2: currentSystem?.[3]?.AHCAT2,
      AHCAT3: currentSystem?.[3]?.AHCAT3,
      AHCAT4: currentSystem?.[3]?.AHCAT4,
      AHCAT5: currentSystem?.[3]?.AHCAT5,
      AHCATEGORY1: currentSystem?.[3]?.AHCATEGORY1,
      AHCATEGORY2: currentSystem?.[3]?.AHCATEGORY2,
      AHDATEUPDATED: currentSystem?.[3]?.AHDATEUPDATED,
      AHDESC1: currentSystem?.[3]?.AHDESC1,
      AHDESC2: currentSystem?.[3]?.AHDESC2,
      AHEQUIP1: currentSystem?.[3]?.AHEQUIP1,
      AHITEMNB: currentSystem?.[3]?.AHITEMNB,
      AHEQUIP3: currentSystem?.[3]?.AHEQUIP3,
      AHLINEID: currentSystem?.[3]?.AHLINEID,
      AHPOWER: currentSystem?.[3]?.AHPOWER,
      AHQTY: currentSystem?.[3]?.AHQTY,
      AHSTATUS: currentSystem?.[3]?.AHSTATUS,
      AHSYNC: currentSystem?.[3]?.AHSYNC,
      AHSYNCTIMESTAMP: currentSystem?.[3]?.AHSYNCTIMESTAMP,
      AHSYSTEMID: currentSystem?.[3]?.AHSYSTEMID,
      AHTYPE: currentSystem?.[3]?.AHTYPE,
      AHUPDATEDBY: currentSystem?.[3]?.AHUPDATEDBY,
      AQCAT1: currentSystem?.[3]?.AQCAT1,
      AQCAT2: currentSystem?.[3]?.AQCAT2,
      AQCAT3: currentSystem?.[3]?.AQCAT3,
      AQCAT4: currentSystem?.[3]?.AQCAT4,
      AQCAT5: currentSystem?.[3]?.AQCAT5,
      AQLINEID: currentSystem?.[3]?.AQLINEID,
      AQSTATUS: systemIsOn,
      AQTYPE: "STAT",
      AQUSERID: currentSystem?.[3]?.AQUSERID,
      AQVALU3: currentSystem?.[3]?.AQVALU3,
      AQVALUE1: 0,
      AQVALUE2: 1,
      AQVALUE4: currentSystem?.[3]?.AQVALUE4,
      AQVALUE5: currentSystem?.[3]?.AQVALUE5,
    });
    setFreqNotifications({
      ...freqNotifications,
      AHCAT1: currentSystem?.[0]?.AHCAT1,
      AHCAT2: currentSystem?.[0]?.AHCAT2,
      AHCAT3: currentSystem?.[0]?.AHCAT3,
      AHCAT4: currentSystem?.[0]?.AHCAT4,
      AHCAT5: currentSystem?.[0]?.AHCAT5,
      AHCATEGORY1: currentSystem?.[0]?.AHCATEGORY1,
      AHCATEGORY2: currentSystem?.[0]?.AHCATEGORY2,
      AHDATEUPDATED: currentSystem?.[0]?.AHDATEUPDATED,
      AHDESC1: currentSystem?.[0]?.AHDESC1,
      AHDESC2: currentSystem?.[0]?.AHDESC2,
      AHEQUIP1: currentSystem?.[0]?.AHEQUIP1,
      AHITEMNB: currentSystem?.[0]?.AHITEMNB,
      AHEQUIP3: currentSystem?.[0]?.AHEQUIP3,
      AHLINEID: currentSystem?.[0]?.AHLINEID,
      AHPOWER: currentSystem?.[0]?.AHPOWER,
      AHQTY: currentSystem?.[0]?.AHQTY,
      AHSTATUS: currentSystem?.[0]?.AHSTATUS,
      AHSYNC: currentSystem?.[0]?.AHSYNC,
      AHSYNCTIMESTAMP: currentSystem?.[0]?.AHSYNCTIMESTAMP,
      AHSYSTEMID: currentSystem?.[0]?.AHSYSTEMID,
      AHTYPE: currentSystem?.[0]?.AHTYPE,
      AHUPDATEDBY: currentSystem?.[0]?.AHUPDATEDBY,
      AQCAT1: currentSystem?.[0]?.AQCAT1,
      AQCAT2: currentSystem?.[0]?.AQCAT2,
      AQCAT3: currentSystem?.[0]?.AQCAT3,
      AQCAT4: currentSystem?.[0]?.AQCAT4,
      AQCAT5: currentSystem?.[0]?.AQCAT5,
      AQLINEID: currentSystem?.[0]?.AQLINEID,
      AQSTATUS: freqIsOn,
      AQTYPE: "FREQ",
      AQUSERID: currentSystem?.[0]?.AQUSERID,
      AQVALU3: currentSystem?.[0]?.AQVALU3,
      AQVALUE1: freqMin,
      AQVALUE2: freqMax,
      AQVALUE4: currentSystem?.[0]?.AQVALUE4,
      AQVALUE5: currentSystem?.[0]?.AQVALUE5,
    });
    setVoltNotifications({
      ...voltNotifications,
      AHCAT1: currentSystem?.[1]?.AHCAT1,
      AHCAT2: currentSystem?.[1]?.AHCAT2,
      AHCAT3: currentSystem?.[1]?.AHCAT3,
      AHCAT4: currentSystem?.[1]?.AHCAT4,
      AHCAT5: currentSystem?.[1]?.AHCAT5,
      AHCATEGORY1: currentSystem?.[1]?.AHCATEGORY1,
      AHCATEGORY2: currentSystem?.[1]?.AHCATEGORY2,
      AHDATEUPDATED: currentSystem?.[1]?.AHDATEUPDATED,
      AHDESC1: currentSystem?.[1]?.AHDESC1,
      AHDESC2: currentSystem?.[1]?.AHDESC2,
      AHEQUIP1: currentSystem?.[1]?.AHEQUIP1,
      AHITEMNB: currentSystem?.[1]?.AHITEMNB,
      AHEQUIP3: currentSystem?.[1]?.AHEQUIP3,
      AHLINEID: currentSystem?.[1]?.AHLINEID,
      AHPOWER: currentSystem?.[1]?.AHPOWER,
      AHQTY: currentSystem?.[1]?.AHQTY,
      AHSTATUS: currentSystem?.[1]?.AHSTATUS,
      AHSYNC: currentSystem?.[1]?.AHSYNC,
      AHSYNCTIMESTAMP: currentSystem?.[1]?.AHSYNCTIMESTAMP,
      AHSYSTEMID: currentSystem?.[1]?.AHSYSTEMID,
      AHTYPE: currentSystem?.[1]?.AHTYPE,
      AHUPDATEDBY: currentSystem?.[1]?.AHUPDATEDBY,
      AQCAT1: currentSystem?.[1]?.AQCAT1,
      AQCAT2: currentSystem?.[1]?.AQCAT2,
      AQCAT3: currentSystem?.[1]?.AQCAT3,
      AQCAT4: currentSystem?.[1]?.AQCAT4,
      AQCAT5: currentSystem?.[1]?.AQCAT5,
      AQLINEID: currentSystem?.[1]?.AQLINEID,
      AQSTATUS: voltageIsOn,
      AQTYPE: "VOLT",
      AQUSERID: currentSystem?.[1]?.AQUSERID,
      AQVALU3: currentSystem?.[1]?.AQVALU3,
      AQVALUE1: voltageMin,
      AQVALUE2: voltageMax,
      AQVALUE4: currentSystem?.[1]?.AQVALUE4,
      AQVALUE5: currentSystem?.[1]?.AQVALUE5,
    });
    setLoadNotifications({
      ...loadNotifications,
      AHCAT1: currentSystem?.[2]?.AHCAT1,
      AHCAT2: currentSystem?.[2]?.AHCAT2,
      AHCAT3: currentSystem?.[2]?.AHCAT3,
      AHCAT4: currentSystem?.[2]?.AHCAT4,
      AHCAT5: currentSystem?.[2]?.AHCAT5,
      AHCATEGORY1: currentSystem?.[2]?.AHCATEGORY1,
      AHCATEGORY2: currentSystem?.[2]?.AHCATEGORY2,
      AHDATEUPDATED: currentSystem?.[2]?.AHDATEUPDATED,
      AHDESC1: currentSystem?.[2]?.AHDESC1,
      AHDESC2: currentSystem?.[2]?.AHDESC2,
      AHEQUIP1: currentSystem?.[2]?.AHEQUIP1,
      AHITEMNB: currentSystem?.[2]?.AHITEMNB,
      AHEQUIP3: currentSystem?.[2]?.AHEQUIP3,
      AHLINEID: currentSystem?.[2]?.AHLINEID,
      AHPOWER: currentSystem?.[2]?.AHPOWER,
      AHQTY: currentSystem?.[2]?.AHQTY,
      AHSTATUS: currentSystem?.[2]?.AHSTATUS,
      AHSYNC: currentSystem?.[2]?.AHSYNC,
      AHSYNCTIMESTAMP: currentSystem?.[2]?.AHSYNCTIMESTAMP,
      AHSYSTEMID: currentSystem?.[2]?.AHSYSTEMID,
      AHTYPE: currentSystem?.[2]?.AHTYPE,
      AHUPDATEDBY: currentSystem?.[2]?.AHUPDATEDBY,
      AQCAT1: currentSystem?.[2]?.AQCAT1,
      AQCAT2: currentSystem?.[2]?.AQCAT2,
      AQCAT3: currentSystem?.[2]?.AQCAT3,
      AQCAT4: currentSystem?.[2]?.AQCAT4,
      AQCAT5: currentSystem?.[2]?.AQCAT5,
      AQLINEID: currentSystem?.[2]?.AQLINEID,
      AQSTATUS: loadIsOn,
      AQTYPE: "LOAD",
      AQUSERID: currentSystem?.[2]?.AQUSERID,
      AQVALU3: currentSystem?.[2]?.AQVALU3,
      AQVALUE1: loadMin,
      AQVALUE2: loadMax,
      AQVALUE4: currentSystem?.[2]?.AQVALUE4,
      AQVALUE5: currentSystem?.[2]?.AQVALUE5,
    });
  };

  const updateOnOff = () => {
    setNotifications({
      ...notifications,
      AQSTATUS: notifications.AQSTATUS === "A" ? "N" : "A",
    });
    setIsSaved(false);
  };

  const updateFOnOff = () => {
    setFreqNotifications({
      ...freqNotifications,
      AQSTATUS: freqNotifications.AQSTATUS === "A" ? "N" : "A",
    });
    setIsSaved(false);
  };

  const updateVOnOff = () => {
    setVoltNotifications({
      ...voltNotifications,
      AQSTATUS: voltNotifications.AQSTATUS === "A" ? "N" : "A",
    });
    setIsSaved(false);
  };

  const updateLOnOff = () => {
    setLoadNotifications({
      ...loadNotifications,
      AQSTATUS: loadNotifications.AQSTATUS === "A" ? "N" : "A",
    });
    setIsSaved(false);
  };

  const userCurrentArray = useSelector((state) => state.menu.userCurrentArray);

  const myDropdown = userCurrentArray?.[0]?.filter(
    (array) => array.AHTYPE.toLowerCase() === props.system
  );

  const updateState = () => {
    const newState = updatedNotifications?.[1]?.map((obj) => {
      if (obj.AQEQUIPID === notifications.AQEQUIPID && obj.AQTYPE === "STAT") {
        return notifications;
      } else if (
        obj.AQEQUIPID === notifications.AQEQUIPID &&
        obj.AQTYPE === "FREQ"
      ) {
        return freqNotifications;
      } else if (
        obj.AQEQUIPID === notifications.AQEQUIPID &&
        obj.AQTYPE === "VOLT"
      ) {
        return voltNotifications;
      } else if (
        obj.AQEQUIPID === notifications.AQEQUIPID &&
        obj.AQTYPE === "LOAD"
      ) {
        return loadNotifications;
      }

      return obj;
    });
    updatedNotifications[1] = newState;
    const specificNoti = updatedNotifications?.[1].filter(
      (filter) => filter.AQEQUIPID === systemEqId
    );
    httpUpdate.put("dataset", "notificationdata", specificNoti).then(
      (response) => {
        setIsLoading(false);
      },
      (error) => {
        console.log("error request");
        setHttpError(`${error.message}`);
      }
    );
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isSaved, setIsSaved] = useState(true);

  return (
    <Box
      sx={{
        ".css-1vnoats-MuiPaper-root-MuiAccordion-root.Mui-expanded:last-of-type":
          {
            backgroundColor: "transparent",
            color: "black",
          },
        ".css-1vnoats-MuiPaper-root-MuiAccordion-root:first-of-type": {
          backgroundColor: "transparent",
          color: "black",
        },
        ".css-3xhl31-MuiSvgIcon-root": {
          color: "black",
        },
        ".css-47hzz3-MuiSlider-root": {
          width: "50% !important",
        },
        ".css-1h0jiu2": {
          backgroundColor: "transparent",
          color: "black",
        },
        ".css-1n3veo1": {
          color: "black",
        },
        ".css-8mig72": { color: "black" },
      }}
    >
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>{props.systemName}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid>
            <Grid sx={{ textAlign: "center", marginBottom: "2%" }}>
              <Grid item xs={12}>
                <FormControl
                  variant="outlined"
                  sx={{
                    width: "100%",
                    color: "black",
                    marginTop: "5px",
                    ".css-9425fu-MuiOutlinedInput-notchedOutline": {
                      color: "black",
                    },
                    ".css-1faml0z-MuiSvgIcon-root-MuiSelect-icon": {
                      color: "black",
                    },
                  }}
                >
                  <InputLabel id="systems" sx={{ color: "black" }}>
                    Systems
                  </InputLabel>
                  <Select
                    label="Systems"
                    labelId="systems"
                    width="100%"
                    sx={{ color: "black" }}
                    onChange={(e) => {
                      if (isSaved) {
                        updateSystem(e.target.value - 1);
                      }
                      // if (
                      //   !isFound.includes(`${notifications.systemEquipId}`) &&
                      //   notifications.systemEquipId !== 0
                      // ) {
                      //   setEditedNotif((old) => [...old, notifications]);
                      // }
                    }}
                    value={systemIndex}
                    defaultValue={null}
                  >
                    <MenuItem value="0" disabled>
                      Select System...
                    </MenuItem>
                    {myDropdown?.map((menu, i) => {
                      return (
                        <MenuItem
                          key={i + 1}
                          value={i + 1}
                          defaultValue={null}
                          onClick={() => {
                            if (isSaved) {
                              updateSystem(i);
                              setSystemIndex(i + 1);
                            }
                          }}
                          onBlur={() => {
                            onBlurSystems();
                          }}
                        >
                          {menu?.AHDESC1}{" "}
                          {props.system === "generator" ? "" : i + 1}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {!isSaved && (
                  <Grid sx={{ color: "red", fontSize: "10px" }}>
                    Save Changes First!
                  </Grid>
                )}
              </Grid>
            </Grid>
            {systemEqId && (
              <Grid>
                <Grid container>
                  <Grid item xs={3}></Grid>
                  <Grid
                    item
                    xs={5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    ON/OFF
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Checkbox
                      sx={{ color: "black" }}
                      checked={notifications.AQSTATUS === "A"}
                      onChange={() => {
                        updateOnOff();
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container marginY={"1%"}>
                  <Grid item xs={3}></Grid>
                  <Grid
                    item
                    container
                    xs={5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid
                      item
                      xs={5}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {"Frequency "}
                      {"  "}
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Slider
                        getAriaLabel={() => "Temperature range"}
                        value={[
                          freqNotifications.AQVALUE1,
                          freqNotifications.AQVALUE2,
                        ]}
                        onChange={handleFrequencyChange}
                        valueLabelDisplay="on"
                        getAriaValueText={valuetext}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Checkbox
                      sx={{ color: "black" }}
                      checked={freqNotifications.AQSTATUS === "A"}
                      onChange={() => updateFOnOff()}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={3}></Grid>
                  <Grid
                    item
                    container
                    xs={5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid
                      item
                      xs={5}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {"Voltage"}{" "}
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Slider
                        getAriaLabel={() => "Temperature range"}
                        value={[
                          voltNotifications.AQVALUE1,
                          voltNotifications.AQVALUE2,
                        ]}
                        onChange={handleVoltageChange}
                        valueLabelDisplay="on"
                        getAriaValueText={valuetext}
                        size="small"
                        min={100}
                        max={500}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Checkbox
                      sx={{ color: "black" }}
                      checked={voltNotifications.AQSTATUS === "A"}
                      onChange={() => updateVOnOff()}
                    />
                  </Grid>
                </Grid>
                <Grid container marginY={"1%"}>
                  <Grid item xs={3}></Grid>
                  <Grid
                    item
                    container
                    xs={5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid
                      item
                      xs={5}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      {"Power %"}{" "}
                    </Grid>
                    <Grid
                      item
                      xs={7}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Slider
                        getAriaLabel={() => "Temperature range"}
                        value={[
                          loadNotifications.AQVALUE1,
                          loadNotifications.AQVALUE2,
                        ]}
                        onChange={handleLoadChange}
                        valueLabelDisplay="on"
                        getAriaValueText={valuetext}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={3}
                    sx={{ display: "flex", justifyContent: "center" }}
                  >
                    <Checkbox
                      sx={{ color: "black" }}
                      checked={loadNotifications.AQSTATUS === "A"}
                      onChange={() => updateLOnOff()}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={9}></Grid>
                  <Grid item xs={3}>
                    <Grid
                      container
                      sx={{
                        display: "flex",
                        justifyContent: "right",
                        marginTop: "2%",
                      }}
                    >
                      <Grid
                        sx={{
                          backgroundColor: "#33a1c9",
                          textAlign: "center",
                          borderRadius: "7px",
                        }}
                      >
                        <Button
                          sx={{ color: "white", width: "100%", border: "none" }}
                          onClick={handleClickOpen}
                          disabled={isSaved}
                        >
                          Save
                        </Button>
                        <ConfirmationAlert
                          onClick={() => {
                            updateState();
                            setIsSaved(true);
                          }}
                          description="Are you sure you want to save changes?"
                          handleClose={handleClose}
                          open={open}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
