import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Box } from "@mui/material";

ChartJS.register(ArcElement, Tooltip, Legend);

function DoughnutChart({ width, height, data }) {
  return (
    <Box
      width={width}
      height={height}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <Doughnut data={data} />
    </Box>
  );
}

export default DoughnutChart;
