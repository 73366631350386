import axios from "axios";
import { HTTP_URL, jwtToken } from "../GlobalVar/commonVar";

async function put(path, cmd, data) {
  return await axios.put(
    HTTP_URL + "/" + path,
    {
      data: data,
      cmd: cmd,
      jwtToken: jwtToken[0],
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
}

const httpUpdate = {
  put,
};

export default httpUpdate;
