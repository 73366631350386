import { Box, Grid } from "@mui/material";
import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import httpReq from "../../../Services/http/httpReq";
import { decodeToken } from "react-jwt";
import BarChart from "../components/BarChart";
import { useSelector } from "react-redux";

function Consumption001() {
  const [diesel, setDiesel] = useState([]);
  const [solar, setSolar] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState("");

  useEffect(() => {
    dieselGraph();
    solarGraph();
  }, []);

  const userCurrentSystem = useSelector(
    (state) => state.menu.userCurrentSystem
  );

  function dieselGraph() {
    var x = {
      SYSTEMID: userCurrentSystem?.[0].AGSYSTEMID,
      TYPE: "DIESEL",
    };
    httpReq.post("datareq", "consumption001", x).then(
      (response) => {
        var _decodedResponse = decodeToken(response.data);
        if (_decodedResponse.reply === "consumption001") {
          var consumption001 = JSON.parse(_decodedResponse.data);
          setDiesel(consumption001);
        }
        setIsLoading(false);
      },
      (error) => {
        console.log("error request");
        setHttpError(`${error.message}`);
      }
    );
  }

  function solarGraph() {
    var x = {
      SYSTEMID: userCurrentSystem?.[0].AGSYSTEMID,
      TYPE: "SOLAR",
    };
    httpReq.post("datareq", "consumption001", x).then(
      (response) => {
        var _decodedResponse = decodeToken(response.data);
        if (_decodedResponse.reply === "consumption001") {
          var consumption001 = JSON.parse(_decodedResponse.data);
          setSolar(consumption001);
        }
        setIsLoading(false);
      },
      (error) => {
        console.log("error request");
        setHttpError(`${error.message}`);
      }
    );
  }

  var a;
  var i;
  var j;
  var _newData1 = [];
  var _newData = [];
  var _newData2 = [];

  for (a = 0; a < diesel.length; a++) {
    _newData1[a] = {
      id: Math.random(),
      label: diesel?.[2]?.[a]?.AHDESC1,
    };
  }

  for (i = 0; i < diesel.length; i++) {
    _newData[i] = {
      id: Math.random(),
      daily: diesel[0].length > 0 ? diesel?.[0]?.[i]?.AMVALUE : 0,
      monthly: diesel?.[1]?.[i]?.AMVALUE,
      yearly: diesel?.[2]?.[i]?.AMVALUE,
    };
  }

  for (j = 0; j < solar.length; j++) {
    _newData2[j] = {
      id: Math.random(),
      daily: solar[0].length > 0 ? solar?.[0]?.[j]?.AMVALUE : 0,
      monthly: solar?.[1]?.[j]?.AMVALUE,
      yearly: solar?.[2]?.[j]?.AMVALUE,
    };
  }

  console.log(diesel);
  const labels = _newData1.map((label) => label.label);

  const barData = {
    labels,
    datasets: [
      {
        label: "Daily",
        data: _newData.map((data) => data.daily),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Monthly",
        data: _newData.map((data) => data.monthly),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Yearly",
        data: _newData.map((data) => data.yearly),
        backgroundColor: "black",
      },
    ],
  };
  const barSolarData = {
    labels,
    datasets: [
      {
        label: "Daily",
        data: _newData2.map((data) => data.daily),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Monthly",
        data: _newData2.map((data) => data.monthly),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Yearly",
        data: _newData2.map((data) => data.yearly),
        backgroundColor: "black",
      },
    ],
  };

  const PieDailydata = {
    labels,
    datasets: [
      {
        label: "%",
        data: _newData.map((data) => data.daily),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const PieMonthlydata = {
    labels,
    datasets: [
      {
        label: "%",
        data: _newData.map((data) => data.monthly),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  const PieYearlydata = {
    labels,
    datasets: [
      {
        label: "%",
        data: _newData.map((data) => data.yearly),
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 206, 86, 0.2)",
          "rgba(75, 192, 192, 0.2)",
          "rgba(153, 102, 255, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };
  return (
    <Box
      width={"100%"}
      height={"100%"}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <Grid container>
        <Grid
          width={"100%"}
          height={"50%"}
          sx={{ display: "flex", justifyContent: "center" }}
          xs={6}
        >
          <BarChart bardata={barData} width="100%" height="100%" />
        </Grid>
        <Grid
          width={"100%"}
          height={"50%"}
          sx={{ display: "flex", justifyContent: "center" }}
          xs={6}
        >
          <BarChart bardata={barSolarData} width="100%" height="100%" />
        </Grid>
      </Grid>
      {/* <Grid container>
        <Grid item xs={4}>
          <PieChart data={PieDailydata} width="auto" height="300px" />
        </Grid>
        <Grid item xs={4}>
          <PieChart data={PieMonthlydata} width="auto" height="300px" />
        </Grid>
        <Grid item xs={4}>
          <PieChart data={PieYearlydata} width="auto" height="300px" />
        </Grid>
      </Grid> */}
    </Box>
  );
}

export default Consumption001;
