import React, { useState } from "react";
import Box from "@mui/material/Box";

import "./App.css";
import LoginForm from "./Pages/common/Login/loginFrm";
import Layout from "../src/Pages/common/layout";
import { Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import store from "../src/Services/redux/store";
import MainLayout from "./Pages/common/Main/MainLayout";
import DefaultPage from "./Pages/common/DefaultPage";

import { ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Dash001 from "./Pages/dashboard/dash001";
import Details0010 from "./Pages/dashboard/details";
import { Navigate } from "react-router-dom";
import ProfilePage from "./Pages/profile/profile";

export const UserContext = React.createContext();

function App() {
  const [theme, colorMode] = useMode();
  const [user, setUser] = useState(false);

  return (
    <Box sx={{ height: "100%", backgroundColor: "rgba(247, 245, 245, 0.651)" }}>
      <Provider store={store}>
        <ColorModeContext.Provider value={colorMode}>
          <ThemeProvider theme={theme}>
            {/* <CssBaseline /> */}

            <Box
              bgcolor="rgba(247, 245, 245, 0.651)"
              width="100%"
              height="100%"
            >
              {/* {user ? ( */}
              <Routes>
                <Route
                  exact
                  path="/"
                  element={<LoginForm setUser={setUser} />}
                />
                <Route
                  exact
                  path="/main/*"
                  element={user ? <MainLayout /> : <Navigate to="/" />}
                >
                  <Route
                    exact
                    index
                    element={user ? <DefaultPage /> : <Navigate to="/" />}
                  />
                  {/* <Route
                    exact
                    path="details0010"
                    element={user ? <Details0010 /> : <Navigate to="/" />}
                  /> */}
                  {/* <Route
                    exact
                    path="dash001/details0010"
                    element={user ? <Details0010 /> : <Navigate to="/" />}
                  /> */}
                  <Route
                    exact
                    path="*"
                    element={user ? <DefaultPage /> : <Navigate to="/" />}
                  />
                  <Route
                    exact
                    path="profile"
                    element={user ? <ProfilePage /> : <Navigate to="/" />}
                  />
                  <Route
                    exact
                    path="dash002"
                    element={user ? <ProfilePage /> : <Navigate to="/" />}
                  />
                </Route>

                <Route
                  exact
                  path="/sales001"
                  element={user ? <Layout /> : <Navigate to="/" />}
                />
                <Route
                  exact
                  path="*"
                  element={user ? <DefaultPage /> : <Navigate to="/" />}
                />
              </Routes>
            </Box>
          </ThemeProvider>
        </ColorModeContext.Provider>
      </Provider>
    </Box>
  );
}

export default App;
