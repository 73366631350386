import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import httpReq from "../../../Services/http/httpReq";
import { decodeToken } from "react-jwt";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Table from "../../dashboard/table";

const Logs = () => {
  const userCurrentSystem = useSelector(
    (state) => state.menu.userCurrentSystem
  );
  const userCurrentArray = useSelector((state) => state.menu.userCurrentArray);
  const sortedData = [...userCurrentArray[0]]?.sort((a, b) => {
    return a.AHDESC1.localeCompare(b.AHDESC1);
  });
  //   console.log("sortedData");
  //   console.log(sortedData);
  const [logsData, setLogsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState("");
  const [showValues, setShowValues] = useState({
    id: 0,
    type: "STAT",
    equipid: sortedData?.[0].AHEQUIPID,
    fromDate: moment(new Date()).format("YYYY-MM-DD"),
    toDate: moment(new Date()).format("YYYY-MM-DD"),
  });

  const updateID = (i) => {
    setShowValues({
      ...showValues,
      id: i,
      equipid: sortedData?.[i].AHEQUIPID,
    });
    setLogsData([]);
  };

  const updateType = (e) => {
    setShowValues({
      ...showValues,
      type: e,
    });
  };

  const updateFromDate = (i) => {
    setShowValues({
      ...showValues,
      fromDate: i,
    });
  };

  const updateToDate = (e) => {
    setShowValues({
      ...showValues,
      toDate: e,
    });
  };

  function getLogs() {
    var x = {
      systemid: userCurrentSystem?.[0].AGSYSTEMID,
      equipid: showValues.equipid,
      fromdate: showValues.fromDate,
      todate: showValues.toDate,
      type: showValues.type,
    };

    httpReq.post("datareq", "logsdata", x).then(
      (response) => {
        var _decodedResponse = decodeToken(response.data);
        if (_decodedResponse.reply === "logsdata") {
          var logsdata = JSON.parse(_decodedResponse.data);
          setLogsData(logsdata);
        }
        setIsLoading(false);
      },
      (error) => {
        console.log("error request");
        setHttpError(`${error.message}`);
      }
    );
  }

  const columns = [
    { field: "date", headerName: "Date", width: 200 },
    { field: "type", headerName: "Type", width: 200 },
    { field: "description", headerName: "Description", width: 200 },
    { field: "value", headerName: "Value", width: 200 },
  ];

  var i;
  var _logsData = [];

  for (i = 0; i < logsData.length; i++) {
    _logsData[i] = {
      id: Math.random(),
      date: moment(logsData?.[i].ARTIMESTAMP).format("YYYY-MM-DD HH:mm:ss"),
      type: logsData?.[i].ARTYPE,
      description: logsData?.[i].ARLOGTXT,
      value: logsData?.[i].ARVALUE1,
    };
  }

  return (
    <Box>
      <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: "100%" }}>
        <DemoContainer
          components={["DateField"]}
          sx={{
            width: "125%",
            overflow: "hidden",
            marginLeft: "-12.5%",
            //   marginRight: "-12%",
          }}
        >
          <Box width={"100%"}>
            <Grid>
              <Grid
                container
                item
                textAlign={"center"}
                display={"flex"}
                alignItems={"center"}
                marginTop={"-1.5%"}
                // marginBottom={"-1%"}
                sx={{
                  height: "100px",
                  //   backgroundColor: "#c3e3e5",
                  width: "100%",
                }}
                xs={12}
              >
                <Grid item xs={0.2}></Grid>
                <Grid item xs={1.9}>
                  <FormControl
                    sx={{
                      width: "100%",
                      color: "black",
                      marginTop: "5px",
                      ".css-9425fu-MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                        color: "black",
                      },
                      ".css-1faml0z-MuiSvgIcon-root-MuiSelect-icon": {
                        color: "black",
                      },
                      ".css-nqlg3w": {
                        borderColor: "black",
                      },
                      ".css-8mig72": {
                        color: "black",
                      },
                    }}
                  >
                    <InputLabel sx={{ color: "black" }}>Systems</InputLabel>
                    <Select
                      label="Systems"
                      width="100%"
                      sx={{ color: "black" }}
                      onChange={(e) => updateID(e.target.value)}
                      value={showValues.id}
                    >
                      {sortedData?.map((menu, i) => (
                        <MenuItem key={i} value={i} onClick={() => updateID(i)}>
                          {menu?.AHDESC1}{" "}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={0.3}></Grid>
                <Grid item xs={1.9}>
                  <FormControl
                    sx={{
                      width: "100%",
                      color: "black",
                      marginTop: "5px",
                      ".css-9425fu-MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                        color: "black",
                      },
                      ".css-1faml0z-MuiSvgIcon-root-MuiSelect-icon": {
                        color: "black",
                      },
                      ".css-nqlg3w": {
                        borderColor: "black",
                      },
                      ".css-8mig72": {
                        color: "black",
                      },
                    }}
                  >
                    <InputLabel sx={{ color: "black" }}>Type</InputLabel>
                    <Select
                      label="Type"
                      width="100%"
                      sx={{ color: "black" }}
                      onChange={(e) => updateType(e.target.value)}
                      value={showValues.type}
                    >
                      <MenuItem
                        value="STAT"
                        onClick={() => updateType("STAT")}
                        defaultChecked
                      >
                        Status
                      </MenuItem>
                      <MenuItem value="FREQ" onClick={() => updateType("FREQ")}>
                        Frequency
                      </MenuItem>
                      <MenuItem value="VOLT" onClick={() => updateType("VOLT")}>
                        Voltage
                      </MenuItem>
                      <MenuItem value="LOAD" onClick={() => updateType("LOAD")}>
                        LOAD
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={0.3}></Grid>
                <Grid item xs={2.6}>
                  <DatePicker
                    label="From Date"
                    value={dayjs(showValues.fromDate)}
                    maxDate={dayjs(showValues.toDate)}
                    onChange={(newValue, date) => {
                      updateFromDate(
                        moment(newValue.unix() * 1000).format("YYYY-MM-DD")
                      );
                    }}
                    sx={{
                      ".css-9425fu-MuiOutlinedInput-notchedOutline": {
                        color: "black !important",
                        borderColor: "black !important",
                      },
                      ".css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input": {
                        color: "black !important",
                        borderColor: "black !important",
                      },
                      ".css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          color: "black !important",
                        },
                      "fieldset.MuiOutlinedInput-notchedOutline.css-9425fu-MuiOutlinedInput-notchedOutline:hover":
                        {
                          borderColor: "black !important",
                        },
                      ".css-nqlg3w": {
                        borderColor: "black",
                      },
                      ".css-putrnr": {
                        color: "black",
                      },
                    }}
                    components={{
                      OpenPickerIcon: () => {
                        return <CalendarMonthIcon sx={{ color: "#064987" }} />;
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: "color: black",
                        input: "color:black",
                      },
                    }}
                    inputFormat="YYYY-MM-DD"
                    PaperProps={{
                      sx: {
                        position: "fixed",
                        left: { xs: -130, lg: -211 },
                        width: { lg: "471px" },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={0.1}></Grid>
                <Grid item xs={2.6}>
                  <DatePicker
                    label="To Date"
                    value={dayjs(showValues.toDate)}
                    minDate={dayjs(showValues.fromDate)}
                    maxDate={dayjs(Date.now())}
                    onChange={(newValue) => {
                      updateToDate(
                        moment(newValue.unix() * 1000).format("YYYY-MM-DD")
                      );
                    }}
                    sx={{
                      ".css-9425fu-MuiOutlinedInput-notchedOutline": {
                        color: "black !important",
                        borderColor: "black !important",
                      },
                      ".css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input": {
                        color: "black !important",
                        borderColor: "black !important",
                      },
                      ".css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          color: "black !important",
                        },
                      "fieldset.MuiOutlinedInput-notchedOutline.css-9425fu-MuiOutlinedInput-notchedOutline:hover":
                        {
                          borderColor: "black !important",
                        },
                      ".css-nqlg3w": {
                        borderColor: "black",
                      },
                      ".css-putrnr": {
                        color: "black",
                      },
                    }}
                    components={{
                      OpenPickerIcon: () => {
                        return <CalendarMonthIcon sx={{ color: "#064987" }} />;
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: "color: black",
                        input: "color:black",
                      },
                    }}
                    inputFormat="YYYY-MM-DD"
                    PaperProps={{
                      sx: {
                        position: "fixed",
                        left: { xs: -130, lg: -211 },
                        width: { lg: "471px" },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={0.3}></Grid>
                <Grid
                  item
                  xs={1.5}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    sx={{
                      width: "100%",
                      backgroundColor: "#064987",
                      color: "white",
                    }}
                    onClick={() => {
                      getLogs();
                    }}
                  >
                    Show
                  </Button>
                </Grid>
                <Grid item xs={0.2}></Grid>
              </Grid>
            </Grid>
          </Box>
        </DemoContainer>
      </LocalizationProvider>
      <Grid sx={{ marginY: "1%", width: "125%", marginLeft: "-12.5%" }}>
        <Table columns={columns} _newData={_logsData} />
      </Grid>
    </Box>
  );
};

export default Logs;
