import "../common/layout.css";
import React from "react";

import SvgComponent001 from "../dashboard/components/DashboardDiagram";

const Layout = () => {
  return (
    <div className="layout-container " style={{ maxHeight: "1500px" }}>
      <SvgComponent001 />
    </div>
  );
};

export default Layout;
