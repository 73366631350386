import * as React from "react";
import Box from "@mui/material/Box";

import { Navigate, Route, Routes } from "react-router-dom";
import Dash001 from "../../dashboard/dash001";
import Topbar from "./components/Topbar";
import { useSelector } from "react-redux";

import "./mainFrm.css";
import DefaultPage from "../DefaultPage";
import Sidebar from "./components/Sidebar";
import { Grid } from "@mui/material";
import Details0010 from "../../dashboard/details";
import ProfilePage from "../../profile/profile";

export default function MainLayout() {
  const userMenu = useSelector((state) => state.menu.userMenu);

  return (
    <div className="mainDiv">
      <Grid container>
        <Grid
          item
          lg={0.6}
          md={0.8}
          xs={1}
          sx={{ height: "100%", width: "100%" }}
        >
          <Sidebar />
        </Grid>
        <Grid item lg={11.4} md={11.2} xs={11}>
          <Box width="5px" className="content" />
          <main className="content">
            <Box className="topbar">
              <Topbar />
            </Box>
            <Box sx={{ overflow: "hidden" }}>
              <Routes>
                <Route
                  path="/"
                  element={<Navigate to={userMenu[0].AFPAGE} />}
                />
                <Route exact path="dash001" element={<Dash001 />} />
                <Route exact path="generator" element={<Details0010 />} />
                <Route exact path="inverter" element={<Details0010 />} />
                <Route exact path="load" element={<Details0010 />} />
                <Route exact path="profile" element={<ProfilePage />} />
                <Route exact path="admin001" element={<DefaultPage />} />
                <Route exact path="grid" element={<Details0010 />} />
                <Route exact path="dash002" element={<ProfilePage />} />
              </Routes>
            </Box>
          </main>
        </Grid>
      </Grid>
    </div>
  );
}
