import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import httpReq from "../../../Services/http/httpReq";
import { decodeToken } from "react-jwt";
import { Box, Button, Grid } from "@mui/material";
import BarChart from "../components/BarChart";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import { useLocation } from "react-router-dom";
import Table from "../table";
import TableViewIcon from "@mui/icons-material/TableView";
import Spline from "../components/Spline";
import newColorFind from "../../common/RandomColors/newColorFind";

const WeeklyConsumption = () => {
  const [diesel, setDiesel] = useState([]);
  const [power, setPower] = useState([]);
  const [isDieselLiter, setIsDieselLiter] = useState(true);
  const location = useLocation();
  const type = location.state;
  const [isLoading, setIsLoading] = useState(true);
  const [showDieselTable, setShowDieselTable] = React.useState(false);
  const [showPowerTable, setShowPowerTable] = React.useState(false);
  const [httpError, setHttpError] = useState("");
  const userCurrentSystem = useSelector(
    (state) => state.menu.userCurrentSystem
  );
  const userCurrentArray = useSelector((state) => state.menu.userCurrentArray);

  const myDropdown = userCurrentArray?.[0]?.filter(
    (array) => array.AHTYPE.toLowerCase() === type.toLowerCase()
  );

  useEffect(() => {
    dieselDiagram();
    powerDiagram();
  }, []);

  function dieselDiagram() {
    var x = {
      SYSTEMID: userCurrentSystem?.[0].AGSYSTEMID,
    };
    httpReq.post("datareq", "dashgencons001", x).then(
      (response) => {
        var _decodedResponse = decodeToken(response.data);
        if (_decodedResponse.reply === "dashgencons001") {
          var dashgencons001 = JSON.parse(_decodedResponse.data);
          setDiesel(dashgencons001);
        }
      },
      (error) => {
        console.log("error request");
        setHttpError(`${error.message}`);
      }
    );
  }

  function powerDiagram() {
    var x = {
      SYSTEMID: userCurrentSystem?.[0].AGSYSTEMID,
    };
    httpReq.post("datareq", "dashgenpower001", x).then(
      (response) => {
        var _decodedResponse = decodeToken(response.data);
        if (_decodedResponse.reply === "dashgenpower001") {
          var dashgenpower001 = JSON.parse(_decodedResponse.data);
          setPower(dashgenpower001);
        }
        setIsLoading(false);
      },
      (error) => {
        console.log("error request");
        setHttpError(`${error.message}`);
      }
    );
  }

  const GeneratorsDescription = myDropdown?.map((system) => system.AHDESC1);
  const GeneratorsID = myDropdown?.map((system) => system.AHEQUIPID);

  var i;
  var j;
  var k;
  var d;
  var tablesData = [];
  var dieselLiterData = [];
  var dieselUSDData = [];
  var powerProductionData = [];
  var date = [];
  var dieselLiter = [];
  var dieselUSD = [];
  var powerProduction = [];
  var dieselLiterTable = [];
  var dieselUSDTable = [];
  var powerTable = [];

  const labels = date;

  for (d = 0; d < diesel?.[0]?.length; d++) {
    date.push(diesel?.[0]?.[d]?.[0]?.AMDATE);
  }

  tablesData[0] = { field: "AMDATE", headerName: "Date", width: 150 };

  for (i = 0; i < GeneratorsDescription?.length; i++) {
    for (j = 0; j < diesel?.[i]?.length; j++) {
      if (
        `${diesel?.[i]?.[j]?.[0]?.AHDESC1}`.trim().toLowerCase() ==
        `${GeneratorsDescription[i]}`.trim().toLowerCase()
      ) {
        dieselLiter.push(diesel?.[i]?.[j]?.[0]?.AMVALUE);
        dieselUSD.push(diesel?.[i]?.[j]?.[0]?.AMCOST);
      }
    }
    for (k = 0; k < power?.[i]?.length; k++) {
      if (
        `${power?.[i]?.[k]?.[0]?.AHDESC1}`.trim().toLowerCase() ==
        `${GeneratorsDescription[i]}`.trim().toLowerCase()
      ) {
        powerProduction.push(power?.[i]?.[k]?.[0]?.AJPOWER);
      }
    }
    dieselLiterData[i] = {
      label: GeneratorsDescription[i],
      data: dieselLiter,
      backgroundColor: newColorFind(i),
    };

    dieselUSDData[i] = {
      label: GeneratorsDescription[i],
      data: dieselUSD,
      backgroundColor: newColorFind(i),
    };

    powerProductionData[i] = {
      label: GeneratorsDescription[i],
      data: powerProduction,
      backgroundColor: newColorFind(i),
    };

    tablesData[i + 1] = {
      field: GeneratorsID[i],
      headerName: GeneratorsDescription[i],
      width: 150,
    };
    var k = 0;

    dieselLiter = [];
    dieselUSD = [];
    powerProduction = [];
  }

  var tableFields = [];
  for (k = 0; k < tablesData.length; k++) {
    tableFields[k] = tablesData[k].field;
  }

  for (k = 0; k < diesel[0]?.length; k++) {
    dieselLiterTable[k] = [];
    dieselUSDTable[k] = [];
    powerTable[k] = [];
    for (i = 0; i < tableFields?.length; i++) {
      dieselLiterTable[k][tableFields[i]] = diesel[0][k][0][tableFields[i]];
      dieselUSDTable[k][tableFields[i]] = diesel[0][k][0][tableFields[i]];
      powerTable[k][tableFields[i]] = diesel[0][k][0][tableFields[i]];
    }
  }
  // set values for diesel table (L & USD)
  for (i = 0; i < dieselLiterTable.length; i++) {
    dieselLiterTable[i].id = Math.random();
    dieselUSDTable[i].id = Math.random();
    powerTable[i].id = Math.random();
    for (k = 0; k < diesel.length; k++) {
      if (dieselLiterTable[i].AMDATE == diesel[k][i][0].AMDATE) {
        dieselLiterTable[i][diesel[k][i][0].AMEQUIPID] =
          diesel[k][i][0].AMVALUE;
        dieselUSDTable[i][diesel[k][i][0].AMEQUIPID] = diesel[k][i][0].AMCOST;
        powerTable[i][power?.[k]?.[i]?.[0]?.AJEQUIPID] = parseFloat(
          power?.[k]?.[i]?.[0]?.AJPOWER
        ).toFixed(1);
      }
    }
  }

  const barColumns = tablesData;

  const dieselChart = {
    labels,
    datasets: isDieselLiter ? dieselLiterData : dieselUSDData,
  };

  const powerProductionChart = {
    labels,
    datasets: powerProductionData,
  };

  return (
    <Box width={"100%"} sx={{ textAlign: "center", marginBottom: "2%" }}>
      <Grid container width={"100%"} sx={{ textAlign: "center" }}>
        <Grid
          sx={{
            textAlign: "center",
            width: "100%",
            marginY: "1%",
            color: "#064987",
            fontSize: "1.5em",
          }}
          container
        >
          <Grid item xs={0.5}></Grid>
          <Grid
            item
            xs={5}
            boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            padding={"20px"}
          >
            <Grid container>
              <Grid item xs={10}>
                {isDieselLiter
                  ? "Estimated Diesel Consumption (L) - Last Week"
                  : "Estimated Diesel Cost (USD) - Last Week"}
              </Grid>
              <Grid item xs={2} container>
                <Grid item xs={5}>
                  <Button onClick={() => setShowDieselTable(!showDieselTable)}>
                    <TableViewIcon sx={{ height: "50%", color: "#064987" }} />
                  </Button>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={5}>
                  <Button onClick={() => setIsDieselLiter(!isDieselLiter)}>
                    <ChangeCircleOutlinedIcon
                      sx={{ height: "50%", color: "#064987" }}
                    />
                  </Button>
                </Grid>
                {/* <Grid sx={{ marginLeft: "5%" }}></Grid> */}
              </Grid>
            </Grid>
            <Grid>
              {!showDieselTable ? (
                <BarChart bardata={dieselChart} width="100%" height="100%" />
              ) : (
                <Table
                  columns={barColumns}
                  _newData={isDieselLiter ? dieselLiterTable : dieselUSDTable}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid
            item
            xs={5}
            boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
            padding={"20px"}
          >
            <Grid container>
              <Grid item xs={11}>
                Generators Power Production (KWh) - Last Week
              </Grid>
              <Grid item xs={1}>
                <Grid sx={{ marginLeft: "5%" }}>
                  <Button onClick={() => setShowPowerTable(!showPowerTable)}>
                    <TableViewIcon sx={{ height: "50%", color: "#064987" }} />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid>
              {!showPowerTable ? (
                <Spline
                  data={powerProductionChart}
                  width="100%"
                  height="100%"
                />
              ) : (
                <Table columns={barColumns} _newData={powerTable} />
              )}
            </Grid>
          </Grid>
          <Grid item xs={0.5}></Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
{
  /* <Grid
  width={"100%"}
  height={"100%"}
  sx={{ display: "flex", justifyContent: "center" }}
  xs={12}
>
  <Grid container>
    <Grid item xs={0.5}></Grid>
    <Grid item xs={5}>
      <HBarChart HBarData={dieselChart} width="100%" height="100%" />
    </Grid>
    <Grid item xs={1}></Grid>
    <Grid item xs={5}>
      <AreaChart areaData={powerProductionChart} width="100%" height="100%" />
    </Grid>
    <Grid item xs={0.5}></Grid>
  </Grid>
</Grid> */
}

export default WeeklyConsumption;
