import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../../../theme";
import { useSelector } from "react-redux";

const Header = ({ subtitle }) => {
  const currPage = useSelector((state) => state.menu.currentPage);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box>
      <Typography variant="h2" color="white" fontWeight="bold">
        {currPage}
      </Typography>
      <Typography variant="h5" color={colors.black}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
