import axios from "axios";

import { useNavigate } from "react-router-dom";

const API_URL = "https://tms.taqa-energies.com:8443/usrwebtms/";

async function login(username, password) {
  return await axios.post(
    API_URL + "login",
    {
      userid: username,
      passid: password,
      cmd: "intlogin",
    },
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
}

function Logout() {
  const navigate = useNavigate();

  localStorage.removeItem("user");
  navigate("/");
}

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const AuthService = {
  login,
  Logout,
  getCurrentUser,
};

export default AuthService;
