import React, { Component } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto"; // ADD THIS
import { Box } from "@mui/material";

export default class Spline extends Component {
  render() {
    return (
      <Box width={this.props.width} height={this.props.height}>
        <Line data={this.props.data} />
      </Box>
    );
  }
}
