import React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid";

function CustomToolbar() {
  return (
    <GridToolbarContainer
      sx={{ display: "flex", justifyContent: "right", border: "none" }}
    >
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
}

function Table(props) {
  const _newData = props._newData;
  const columns = props.columns;

  const rows = _newData;

  return (
    <Box sx={{ height: "auto", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{
          toolbar: CustomToolbar,
        }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        sx={{
          color: "black",
          ".css-1iyq7zh-MuiDataGrid-columnHeaders, .css-wop1k0-MuiDataGrid-footerContainer, .css-vcjdx3, .css-9ffb5l, .css-k008qs, .css-y599qu":
            {
              backgroundColor: "#064987 !important",
              color: "white !important",
              width: "100% !important",
              borderRadius: "0px",
              minHeight: "10px !important",
              maxHeight: "20px !important",
              lineHeight: "15px !important",
              display: "flex",
              alignItems: "center",
            },
          ".css-axafay-MuiDataGrid-virtualScroller, .css-1inm7gi": {
            backgroundColor: "#E0E0E0 !important",
            color: "black !important",
          },
          ".css-z5qlpv-MuiDataGrid-toolbarContainer,.css-11r2tod": {
            backgroundColor: "#064987 !important",
            minHeight: "10px",
            maxHeight: "20px",
            lineHeight: "15px",
            display: "flex",
            alignItems: "center",
          },
          ".css-1ny7bi9-MuiButtonBase-root-MuiButton-root,.css-1i308bc": {
            color: "white",
          },
          // ".css-eqz3ze-MuiDataGrid-root, .css-1iyq7zh-MuiDataGrid-columnHeaders":
          //   {
          //     minHeight: "10px !important",
          //     maxHeight: "20px !important",
          //     lineHeight: "15px !important",
          //   },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </Box>
  );
}

export default Table;
