import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import faker from "faker";
import { Box } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export default function HBarChart({ width, height, HBarData }) {
  const options = {
    indexAxis: "y",
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    dataPointMaxWidth: 100,
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
    },
  };
  return (
    <Box
      width={width}
      height={height}
      sx={{ display: "flex", justifyContent: "center" }}
    >
      <Bar options={options} data={HBarData} />
    </Box>
  );
}
