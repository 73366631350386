import React from "react";
import { useNavigate } from "react-router-dom";
import "./style/loginFrm.css";

import AuthService from "../../../Services/http/auth.service";
import {
  setUserInfo,
  setUserMenu,
  setCurrPageDesc,
  setUserSystems,
  setUserArrays,
} from "../../../Services/redux/reducers/userMenuReducer";
import { useDispatch } from "react-redux";
import { decodeToken } from "react-jwt";
import logo from "../../../Services/images/taqaLogo.png";
import Box from "@mui/material/Box";

import {
  userLoginInfo,
  userLoginMenu,
  jwtToken,
} from "./../../../Services/GlobalVar/commonVar";

function LoginForm(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const setUser = props.setUser;

  const handleSubmit = (event) => {
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    AuthService.login(uname.value, pass.value).then(
      (response) => {
        if (response.data[0].reply === "USRCHKOK") {
          jwtToken[0] = response.data[1].USRDATA;

          var y = JSON.parse(decodeToken(response.data[1].USRDATA).data)[0];
          console.log("Y : ");
          console.log(y);
          console.log(JSON.parse(decodeToken(response.data[1].USRDATA).data));
          localStorage.setItem("user", JSON.stringify(y));

          userLoginInfo[0] = JSON.parse(
            decodeToken(response.data[1].USRDATA).data
          )[0];

          var _userMenu = JSON.parse(
            decodeToken(response.data[1].USRDATA).data
          )[1];
          userLoginMenu[0] = JSON.parse(
            decodeToken(response.data[1].USRDATA).data
          )[1];

          var _userSystems = JSON.parse(
            decodeToken(response.data[1].USRDATA).data
          )[2];

          var _userArrays = JSON.parse(
            decodeToken(response.data[1].USRDATA).data
          )[3];

          dispatch(setUserMenu(_userMenu));
          dispatch(setUserInfo(y));

          dispatch(setCurrPageDesc(_userMenu[0].AFPAGEDESC));

          dispatch(setUserSystems(_userSystems));
          dispatch(setUserArrays(_userArrays));
          setUser(true);
          navigate("/main");
        } else {
          localStorage.removeItem("user");
          setUser(false);
        }
      },
      (error) => {
        console.log("error login");
        console.log(error);
      }
    );
  };

  // JSX code for login form
  const renderForm = (
    <div className="formDiv">
      <form className="form">
        <div>
          <img alt="" className="logo001" src={logo} />
        </div>
        <div className="formDiv001">
          <div className="title">Sign In</div>
          <div className="input-container">
            <label>Username </label>
            <input type="text" name="uname" required />
            {/*{renderErrorMessage("uname")}*/}
          </div>
          <div className="input-container">
            <label>Password </label>
            <input type="password" name="pass" required />
            {/* {renderErrorMessage("pass")}*/}
          </div>
          <div className="button-container">
            <button className="submit-button" onClick={handleSubmit}>
              Sign In
            </button>
          </div>
        </div>
      </form>
    </div>
  );

  return (
    <Box widht="100%" height="100vh">
      <div className="app">{renderForm}</div>

      {/* {localStorage.getItem("user") ? (
        <div className="app">{renderForm}</div>
      ) : (
        <div></div>
      )} */}
    </Box>
  );
}

export default LoginForm;
