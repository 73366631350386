import React from "react";
import { Pie } from "react-chartjs-2";
import { Box } from "@mui/material";
import "chart.js/auto"; // ADD THIS

export default function PieChart({ width, height, data }) {
  return (
    <Box width={width} height={height}>
      <Pie data={data} />
    </Box>
  );
}
