import { Box } from "@mui/material";
import Layout from "../common/layout";
import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function Dash001() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DateField"]}>
        <Box
          display="flex"
          height="100%"
          width="95%"
          flexDirection="column"
          alignContent="center"
          alignItems="center"
          padding="10px"
          margin="10px"
          backgroundColor="rgba(247, 245, 245, 0.651)"
        >
          <Box width={"100%"} sx={{ textAlign: "center" }}>
            <Box
              padding="10px"
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignContent="center"
              width="100%"
              maxHeight="1500px"
              height="100%"
            >
              <Layout />
            </Box>
          </Box>
        </Box>
      </DemoContainer>
    </LocalizationProvider>
  );
}

export default Dash001;
