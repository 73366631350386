import { createSlice } from "@reduxjs/toolkit";
import { userLoginInfo } from "../../GlobalVar/commonVar";

const userMenuInitialState = {
  userMenu: [
    {
      ACUSERID: "-",
    },
  ],
  userInfo: [
    {
      AABUILDING: "-",
    },
  ],
  userSystems: [
    {
      AGDESC1: "-",
    },
  ],
  userCurrentSystem: [
    {
      AGDESC1: "-",
    },
  ],
  userArrays: [
    {
      AGDESC1: "-",
    },
  ],
  userCurrentArray: [
    {
      AGDESC1: "-",
    },
  ],

  currentPage: "TMS",
};

export const menuSlice = createSlice({
  name: "menu",
  initialState: userMenuInitialState,
  reducers: {
    setUserMenu: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userMenu = action.payload;
    },

    setUserInfo: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userInfo = userLoginInfo[0];
    },

    setCurrPageDesc: (state, action) => {
      state.currentPage = action.payload;
    },

    setUserSystems: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userSystems = action.payload;
    },
    setUserCurrentSystem: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userCurrentSystem = action.payload;
    },
    setUserArrays: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userArrays = action.payload;
    },
    setUserCurrentArray: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.userCurrentArray = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setUserMenu,
  setUserInfo,
  setCurrPageDesc,
  setUserSystems,
  setUserCurrentSystem,
  setUserArrays,
  setUserCurrentArray,
} = menuSlice.actions;

export default menuSlice.reducer;
