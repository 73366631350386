import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { useSelector } from "react-redux";
import httpReq from "../../../Services/http/httpReq";
import { decodeToken } from "react-jwt";
import DoughnutChart from "../components/DoughnutChart";
import { useLocation } from "react-router-dom";
import newColorFind from "../../common/RandomColors/newColorFind";
import PieChart from "../components/PieChart";
import PyramidChart from "../components/PyramidChart";
import Pyramid2 from "../components/Pyramid2";
import HBarChart from "../components/HBarChart";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

const MYConsumption = () => {
  const location = useLocation();
  const type = location.state;
  const [monthData, setMonthData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const [solarVsDiesel, setSolarVsDiesel] = useState([]);
  const [showVsValue, setShowVsValue] = React.useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState("");
  const userCurrentSystem = useSelector(
    (state) => state.menu.userCurrentSystem
  );
  const userCurrentArray = useSelector((state) => state.menu.userCurrentArray);

  const myDropdown = userCurrentArray?.[0]?.filter(
    (array) => array.AHTYPE.toLowerCase() === type.toLowerCase()
  );
  const GeneratorsDescription = myDropdown?.map((system) => system.AHDESC1);
  const GeneratorsID = myDropdown?.map((system) => system.AHEQUIPID);

  const currMonth = new Date().getMonth();
  const currYear = new Date().getFullYear();

  const [yearCount, setYearCount] = useState(currYear);

  const [showMonthlyDates, setMonthlyDates] = useState({
    month: currMonth + 1,
    year: currYear,
  });

  const updateMonthDate = (i) => {
    setMonthlyDates({
      ...showMonthlyDates,
      month: i,
    });
    monthlyData();
  };

  const updateYearDate = (e) => {
    setMonthlyDates({
      ...showMonthlyDates,
      year: e,
    });
    monthlyData();
  };

  const handleSubtractOne = () => {
    setYearCount(yearCount - 1);
    solarvsdieselData(yearCount - 1);
  };

  const handleAddOne = () => {
    setYearCount(yearCount + 1);
    solarvsdieselData(yearCount + 1);
  };

  const [monthlyYearDC, setMonthlyYearDC] = useState(currYear);

  const handleSubtractOne2 = () => {
    setMonthlyYearDC(monthlyYearDC - 1);
    yearlyData(monthlyYearDC - 1);
  };

  const handleAddOne2 = () => {
    setMonthlyYearDC(monthlyYearDC + 1);
    yearlyData(monthlyYearDC + 1);
  };

  useEffect(() => {
    monthlyData();
    yearlyData(currYear);
    solarvsdieselData(currYear);
  }, []);

  function monthlyData() {
    var x = {
      SYSTEMID: userCurrentSystem?.[0].AGSYSTEMID,
      TYPE: "diesel",
      PERIOD: "MONTH",
      MONTH: showMonthlyDates.month,
      YEAR: showMonthlyDates.year,
    };
    httpReq.post("datareq", "periodconsumption001", x).then(
      (response) => {
        var _decodedResponse = decodeToken(response.data);
        if (_decodedResponse.reply === "periodconsumption001") {
          var periodconsumption001 = JSON.parse(_decodedResponse.data);
          setMonthData(periodconsumption001);
        }
        setIsLoading(false);
      },
      (error) => {
        console.log("error request");
        setHttpError(`${error.message}`);
      }
    );
  }
  function yearlyData(e) {
    var x = {
      SYSTEMID: userCurrentSystem?.[0].AGSYSTEMID,
      TYPE: "diesel",
      PERIOD: "YEAR",
      YEAR: e,
    };
    httpReq.post("datareq", "periodconsumption001", x).then(
      (response) => {
        var _decodedResponse = decodeToken(response.data);
        if (_decodedResponse.reply === "periodconsumption001") {
          var periodconsumption001 = JSON.parse(_decodedResponse.data);
          setYearData(periodconsumption001);
        }
        setIsLoading(false);
      },
      (error) => {
        console.log("error request");
        setHttpError(`${error.message}`);
      }
    );
    console.log(monthData);
  }

  function solarvsdieselData(year) {
    var x = {
      SYSTEMID: userCurrentSystem?.[0].AGSYSTEMID,
      YEAR: year,
    };
    httpReq.post("datareq", "solarvsdiesel", x).then(
      (response) => {
        var _decodedResponse = decodeToken(response.data);
        if (_decodedResponse.reply === "solarvsdiesel") {
          var solarvsdiesel = JSON.parse(_decodedResponse.data);
          setSolarVsDiesel(solarvsdiesel);
        }
        setIsLoading(false);
      },
      (error) => {
        console.log("error request");
        setHttpError(`${error.message}`);
      }
    );
  }

  const labels = GeneratorsDescription;

  var i;
  var j;
  var monthlyDataValues = [];
  var dataColors = [];
  var monthlyAllData = [];
  var yearlyDataValues = [];
  var yearlyAllData = [];

  var dieselVsMonth = [];
  var dieselVsValue = [];

  for (i = 0; i < monthData.length; i++) {
    monthlyDataValues.push(monthData?.[i]?.[0]?.AMVALUE);
    yearlyDataValues.push(yearData?.[i]?.[0]?.AMVALUE);
    dataColors.push(newColorFind(i));
    monthlyAllData[0] = {
      label: "Monthly Consumption",
      data: monthlyDataValues,
      backgroundColor: dataColors,
      borderColor: dataColors,
      borderWidth: 1,
    };
    yearlyAllData[0] = {
      label: "Yearly Consumption",
      data: yearlyDataValues,
      backgroundColor: dataColors,
      borderColor: dataColors,
      borderWidth: 1,
    };
  }

  for (j = 0; j < solarVsDiesel?.[0]?.length; j++) {
    dieselVsMonth.push(solarVsDiesel?.[0]?.[j]?.AMMONTH);
    dieselVsValue.push(solarVsDiesel?.[0]?.[j]?.AMVALUE);
  }

  const monthlyDataChart = {
    labels,
    datasets: monthlyAllData,
  };

  const yearlyDataChart = {
    labels,
    datasets: yearlyAllData,
  };

  const data = {
    labels: dieselVsMonth,
    datasets: [
      {
        label: "Diesel",
        data: !showVsValue
          ? solarVsDiesel?.[0]?.map((diesel) => diesel.AMVALUE)
          : solarVsDiesel?.[0]?.map((diesel) => diesel.AMCOST),
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
      {
        label: "Solar",
        data: !showVsValue
          ? solarVsDiesel?.[1]?.map((solar) => solar.AMVALUE)
          : solarVsDiesel?.[1]?.map((solar) => solar.AMCOST),
        borderColor: "rgb(53, 162, 235)",
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
    ],
  };

  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const years = Array.from(
    { length: 10 },
    (_, index) => new Date().getFullYear() - index
  );

  return (
    <Box width={"100%"} sx={{ textAlign: "center", marginBottom: "2%" }}>
      <Grid container width={"100%"} sx={{ textAlign: "center" }}>
        <Grid
          sx={{
            textAlign: "center",
            width: "100%",
            marginY: "1%",
            color: "#064987",
            fontSize: "1.5em",
          }}
          container
        >
          <Grid item xs={0.5}></Grid>
          <Grid
            padding={"20px"}
            item
            xs={5}
            boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          >
            <Grid>Estimated Diesel Consumption (L)</Grid>
            <Grid
              container
              height={"30px"}
              sx={{
                ".css-113exh0-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root":
                  { height: "30px" },
              }}
            >
              <Grid item xs={5.5}>
                Monthly
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5.5}>
                Yearly
              </Grid>
            </Grid>
            <Grid
              container
              height={"30px"}
              sx={{
                ".css-113exh0-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root":
                  { height: "30px" },
              }}
            >
              <Grid item xs={5.5} container>
                <Grid item xs={2}></Grid>
                <Grid item xs={3} sx={{ ".css-1xiz3z": { height: "30px" } }}>
                  <FormControl
                    sx={{
                      width: "100%",
                      color: "black",
                      marginTop: "5px",
                      ".css-9425fu-MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                        color: "black",
                      },
                      ".css-1faml0z-MuiSvgIcon-root-MuiSelect-icon": {
                        color: "black",
                      },
                      ".css-nqlg3w": {
                        borderColor: "black",
                      },
                      ".css-8mig72": {
                        color: "black",
                      },
                    }}
                  >
                    <InputLabel sx={{ color: "black" }}>Months</InputLabel>
                    <Select
                      label="Months"
                      width="100%"
                      sx={{ color: "black" }}
                      onChange={(e) => updateMonthDate(e.target.value)}
                      value={showMonthlyDates.month}
                    >
                      {months?.map((month) => (
                        <MenuItem
                          key={month}
                          value={month}
                          onClick={() => updateMonthDate(month)}
                          onBlur={() => updateMonthDate(month)}
                        >
                          {month}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={4} sx={{ ".css-1xiz3z": { height: "30px" } }}>
                  <FormControl
                    sx={{
                      width: "100%",
                      color: "black",
                      marginTop: "5px",
                      ".css-9425fu-MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                        color: "black",
                      },
                      ".css-1faml0z-MuiSvgIcon-root-MuiSelect-icon": {
                        color: "black",
                      },
                      ".css-nqlg3w": {
                        borderColor: "black",
                      },
                      ".css-8mig72": {
                        color: "black",
                      },
                    }}
                  >
                    <InputLabel sx={{ color: "black" }}>Years</InputLabel>
                    <Select
                      label="Years"
                      width="100%"
                      sx={{ color: "black" }}
                      onChange={(e) => updateYearDate(e.target.value)}
                      value={showMonthlyDates.year}
                    >
                      {years?.map((year) => (
                        <MenuItem
                          key={year}
                          value={year}
                          onClick={() => updateYearDate(year)}
                          onBlur={() => updateYearDate(year)}
                        >
                          {year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5.5} container>
                <Grid item xs={4}>
                  {monthlyYearDC > currYear - 10 && (
                    <Button onClick={handleSubtractOne2}>
                      <ArrowCircleLeftIcon />
                    </Button>
                  )}
                </Grid>
                <Grid item xs={4}>
                  {monthlyYearDC}
                </Grid>
                <Grid item xs={4}>
                  {monthlyYearDC < currYear && (
                    <Button onClick={handleAddOne2}>
                      <ArrowCircleRightIcon />
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={5.5}>
                <DoughnutChart
                  data={monthlyDataChart}
                  width="100%"
                  height="100%"
                />
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5.5}>
                <PieChart data={yearlyDataChart} width="100%" height="100%" />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid
            padding={"20px"}
            item
            xs={5}
            boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
          >
            <Grid container>
              <Grid item xs={11}>
                {!showVsValue
                  ? "Diesel Consumption Vs Solar Production (L)"
                  : "Diesel Cost Vs Solar Cost (USD)"}
              </Grid>
              <Grid item xs={1}>
                <Grid sx={{ marginLeft: "5%", display: "inline" }}>
                  <Button onClick={() => setShowVsValue(!showVsValue)}>
                    <ChangeCircleOutlinedIcon
                      sx={{ height: "50%", color: "#064987" }}
                    />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={4}>
                {yearCount > currYear - 10 && (
                  <Button onClick={handleSubtractOne}>
                    <ArrowCircleLeftIcon />
                  </Button>
                )}
              </Grid>
              <Grid item xs={4}>
                {yearCount}
              </Grid>
              <Grid item xs={4}>
                {yearCount < currYear && (
                  <Button onClick={handleAddOne}>
                    <ArrowCircleRightIcon />
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid>
              <HBarChart HBarData={data} width="100%" height="100%" />
              {/* <ComparisionPyramid /> */}
            </Grid>
          </Grid>
          <Grid item xs={0.5}></Grid>
        </Grid>
        {/* <Grid
          width={"100%"}
          height={"100%"}
          sx={{ display: "flex", justifyContent: "center" }}
          item
          xs={12}
        >
          <Grid container>
            <Grid item xs={0.5}>
              <Grid sx={{ marginLeft: "5%" }}>
                <Button onClick={() => setShowVsValue(!showVsValue)}>
                  <ChangeCircleOutlinedIcon
                    sx={{ height: "50%", color: "#064987" }}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default MYConsumption;
