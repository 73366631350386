import * as React from "react";
import Button from "@mui/material/Button";
import { Box, Grid } from "@mui/material";
import { useState } from "react";
import PersonalInfo from "./personalInformation/PersonalInfo";
import NotificationsInfo from "./notifications/Notifications";
import { useSelector } from "react-redux";
import httpReq from "../../Services/http/httpReq";
import { decodeToken } from "react-jwt";
import { useEffect } from "react";
import Logs from "./logs/Logs";

function ProfilePage() {
  const [buttonClicked, setButtonClicked] = useState("NO");
  const [notificationsInfo, setNotificationsInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState("");
  const userCurrentSystem = useSelector(
    (state) => state.menu.userCurrentSystem
  );

  useEffect(() => {
    notifications();
  }, []);

  function notifications() {
    var x = {
      systemid: userCurrentSystem?.[0].AGSYSTEMID,
      userid: 1,
    };
    httpReq.post("datareq", "notificationdata", x).then(
      (response) => {
        var _decodedResponse = decodeToken(response.data);
        if (_decodedResponse.reply === "notificationdata") {
          var notificationdata = JSON.parse(_decodedResponse.data);
          setNotificationsInfo(notificationdata);
        }
        setIsLoading(false);
      },
      (error) => {
        console.log("error request");
        setHttpError(`${error.message}`);
      }
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        marginTop: "4%",
        marginBottom: "500px",
      }}
    >
      <Grid
        container
        sx={{
          width: "100%",
          marginX: "8%",
          backgroundColor: "#E0E0E0!important",
          borderRadius: "7px",
        }}
      >
        <Grid item xs={12} container>
          <Grid
            item
            container
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#33a1c9",
              borderTopRightRadius: "7px",
              borderTopLeftRadius: "7px",
            }}
          >
            {/* <Grid
              item
              xs={4}
              sx={{
                textAlign: "center",
                backgroundColor:
                  buttonClicked === "PI" ? "#005e89" : "transparent",
              }}
              onClick={() => setButtonClicked("PI")}
            >
              <Button sx={{ color: "white", width: "100%" }}>
                Personal Information
              </Button>
            </Grid> */}
            <Grid
              item
              xs={6}
              sx={{
                textAlign: "center",
                backgroundColor:
                  buttonClicked === "NO" ? "#005e89" : "transparent",
              }}
              onClick={() => {
                notifications();
                setButtonClicked("NO");
              }}
            >
              <Button sx={{ color: "white", width: "100%" }}>
                Notifications
              </Button>
            </Grid>
            <Grid
              item
              xs={6}
              sx={{
                textAlign: "center",
                backgroundColor:
                  buttonClicked === "LO" ? "#005e89" : "transparent",
              }}
              onClick={() => setButtonClicked("LO")}
            >
              <Button sx={{ color: "white", width: "100%" }}>Logs</Button>
            </Grid>
          </Grid>
        </Grid>
        {!isLoading && !httpError && (
          <Grid item xs={12} padding={"3% 10%"}>
            {/* {buttonClicked === "PI" && (
              <Grid>
                <PersonalInfo />
              </Grid>
            )} */}
            {buttonClicked === "NO" && (
              <Grid>
                <NotificationsInfo notificationsInfo={notificationsInfo} />
              </Grid>
            )}
            {buttonClicked === "LO" && (
              <Grid>
                <Logs />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default ProfilePage;
