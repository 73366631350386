import * as React from "react";
import styles from "../dashstyle.module.scss";
import styles2 from "./dash.module.scss";
import { decodeToken } from "react-jwt";
import Box from "@mui/material/Box";
import httpReq from "../../../Services/http/httpReq";
import { Button, Grid } from "@mui/material";
import { useSpring, animated, config } from "@react-spring/web";
import { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";

function SvgComponent001(props) {
  const [isHovering, setIsHovering] = React.useState(false);

  const [arrayData, setArrayData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState("");
  const [isPopLoading, setIsPopLoading] = useState(true);
  const [httpPopError, setHttpPopError] = useState("");
  const [generatorId, setGeneratorId] = useState(0);

  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const userCurrentSystem = useSelector(
    (state) => state.menu.userCurrentSystem
  );
  const userCurrentArray = useSelector((state) => state.menu.userCurrentArray);

  const [graphData, setGraphData] = useState([]);
  const [genStatus, setGenStatus] = useState([]);
  const [type, setType] = useState("");

  const myBattery = userCurrentArray?.[0]?.filter(
    (array) => array.AHTYPE === "Battery"
  );

  const images = {
    storage: "/images/storage01.png",
    inverter: "/images/inverter.png",
    grid: "/images/grid.png",
    elecPanel: "/images/electr-panel01.png",
    generator: "/images/generator.png",
    bldg: "/images/bldg.png",
    panels: "/images/panels01.png",
    off: "/images/icons8-generator-64-off.png",
    on: "/images/icons8-generator-64-on.png",
  };

  useEffect(() => {
    data();
  }, []);

  function data() {
    var x = {
      SYSTEMID: userCurrentSystem?.[0].AGSYSTEMID,
    };
    httpReq.post("datareq", "diagramload", x).then(
      (response) => {
        var _decodedResponse = decodeToken(response.data);
        if (_decodedResponse.reply === "diagramload") {
          var diagramload = JSON.parse(_decodedResponse.data);
          setArrayData(diagramload);
          setIsLoading(false);
        }
      },
      (error) => {
        console.log("error request");
        setHttpError(`${error.message}`);
      }
    );
  }
  function graph(type1) {
    setIsPopLoading(true);
    var x = {
      SYSTEMID: userCurrentSystem?.[0].AGSYSTEMID,
      FROMDATE: fromDate,
      TODATE: toDate,
      TYPE: type1,
    };
    httpReq.post("datareq", "ACdash01", x).then(
      (response) => {
        var _decodedResponse = decodeToken(response.data);
        if (_decodedResponse.reply === "ACdash01") {
          var ACdash01 = JSON.parse(_decodedResponse.data);
          setGraphData(ACdash01);
          setIsHovering(true);
        }
        setIsPopLoading(false);
      },
      (error) => {
        console.log("error request");
        setHttpPopError(`${error.message}`);
      }
    );
  }

  function popUp(type1) {
    var x = {
      SYSTEMID: userCurrentSystem?.[0].AGSYSTEMID,
      TYPE: type1,
    };
    httpReq.post("datareq", "componentstatus", x).then(
      (response) => {
        var _decodedResponse = decodeToken(response.data);
        if (_decodedResponse.reply === "componentstatus") {
          var componentstatus = JSON.parse(_decodedResponse.data);
          setGenStatus(componentstatus);
          console.log(genStatus);
        }
        setIsPopLoading(false);
      },
      (error) => {
        console.log("error request");
        setHttpPopError(`${error.message}`);
      }
    );
  }

  var i;
  var _newData = [];

  // var _totalGenPower = 0;
  // var _totalGridPower = 0;
  // var _totalInverterPower = 0;

  for (i = 0; i < graphData[generatorId]?.[0].length; i++) {
    _newData[i] = {
      id: Math.random(),
      date: moment(graphData[generatorId][0][i].AJTIMESTAMP).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      activePowerP1: graphData[generatorId][0][i].AJVALUE,
      activePowerP2: graphData[generatorId][1][i].AJVALUE,
      activePowerP3: graphData[generatorId][2][i].AJVALUE,
    };
    // _totalGenPower = _totalGenPower + graphData[i].AIGENPOWER;
    // _totalGridPower = _totalGenPower + graphData[i].AIGRIDPOWER;
    // _totalInverterPower = _totalGenPower + graphData[i].AIINVERTERPOWER;
  }

  const handleMouseOut = () => {
    setIsHovering(false);
    setGraphData([]);
    setGeneratorId(0);
    popUp("");
    setFromDate(moment(new Date()).format("YYYY-MM-DD"));
    setToDate(moment(new Date()).format("YYYY-MM-DD"));
    setType("");
    setIsPopLoading(true);
  };

  function useAnimatedPath({ toggle, delay }) {
    const [length, setLength] = useState(null);
    const animatedStyle = useSpring({
      strokeDashoffset: toggle ? 0 : length,
      strokeDasharray: length,
      delay,
      config: config.slow,
    });
    return {
      style: animatedStyle,
      ref: (ref) => {
        if (ref) {
          setLength(ref.getTotalLength());
        }
      },
    };
  }

  function DrawCircleMotion({ toggle, d, color, strokeWidth }) {
    return (
      <circle r="2" fill="#0DD31AFF" className="circle">
        <animateMotion dur="3s" repeatCount="indefinite" path={d} />
      </circle>
    );
  }

  function RectInfo({ x, y, width, height, fill, title, info, info2 }) {
    var x1 = parseInt(x) + 2;
    var y1 = parseInt(y) + 5;
    var y2 = parseInt(y1) + 10;
    var y3 = parseInt(y2) + 5;
    return (
      <g>
        <rect
          rx="2"
          style={{ fill: "lightgrey", stroke: "#064987", strokeWidth: "1" }}
          x={x}
          y={y}
          width={width}
          height={height}
        />
        <text x={x1} y={y1} fontSize={5} fill="#064987">
          {title}
        </text>
        <text x={x1} y={y2} fontSize={4} fill="black">
          {info}
        </text>
        <text x={x1} y={y3} fontSize={4} fill="black">
          {info2}
        </text>
      </g>
    );
  }

  function DrawConnector({ toggle, d, color, strokeWidth, strokedash }) {
    const animatedStrokeProps = useAnimatedPath({ toggle });
    const animatedFillStyle = useSpring({
      fill: "none",
      delay: 250,
    });

    return (
      <animated.path
        stroke={color}
        strokeWidth={strokeWidth}
        d={d}
        {...animatedStrokeProps}
        style={{ ...animatedStrokeProps.style, ...animatedFillStyle }}
      />
    );
  }

  const linkStyle = {
    textDecoration: "none",
    padding: "1%",
    color: "white",
    border: "1px solid #064987",
    backgroundColor: "#064987",
    width: "100%",
    height: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "7px",
    fontSize: "0.7rem",
  };

  const popUpStyle = {
    units: {
      color: "#064987",
      display: "inline",
      textAlign: "left",
      fontWeight: "bold",
    },
    numbers: {
      textAlign: "right",
      fontWeight: "bold",
    },
  };

  return (
    <Box sx={{ maxHeight: "1500px" }}>
      {arrayData.length > 0 && !isLoading && !httpError && (
        <Box>
          <Box sx={{ textAlign: "right" }}>
            <Grid sx={{ fontWeight: "bold", display: "inline" }}>
              Last Updated:
            </Grid>
            {"  "}
            {arrayData?.[0]?.AIUPDATEDTIME}
          </Box>
          <Box
            display="flex"
            position="relative"
            flexDirection="row"
            justifyContent="center"
            alignContent="center"
            sx={{
              paddingTop: "10px",
              opacity: isHovering ? "0.2" : "1",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              xmlSpace="preserve"
              width="100%"
              viewBox="0 0 420.755 236.555"
              {...props}
            >
              <g transform="translate(107.473 12.831)">
                <path
                  d="M150.608 128.406v29.323 3.307-3.307 6.614-6.614 6.614H-13.308"
                  style={{
                    fill: "none",
                    fillRule: "evenodd",
                    stroke:
                      `${arrayData[0]?.AICAT3}` === "Y" ? "#008000" : "gray",
                    strokeWidth: 0.737453,
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeDasharray: "none",
                    strokeOpacity: 1,
                  }}
                />
                {`${arrayData[0]?.AICAT3}` === "Y" ? (
                  <DrawCircleMotion d="M150.608 128.406v29.323c0 3.307-3.307 6.614-6.614 6.614H-13.308" />
                ) : (
                  <svg
                    style={{ color: "red", position: "absolute" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="330"
                    x="20"
                    y="-1"
                    fill="currentColor"
                    class="bi bi-x"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 
                  .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 
                  0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                      fill="red"
                    ></path>
                  </svg>
                )}
                <path
                  d="M105 74.175h10.567 0 6.615 3.307 6.615 6.614v32.374"
                  style={{
                    fill: "none",
                    fillRule: "evenodd",
                    stroke:
                      `${arrayData[0]?.AICAT4}` ||
                      `${arrayData[0]?.AICAT5}` === "Y"
                        ? "#008000"
                        : "gray",
                    strokeWidth: 0.787516,
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeDasharray: "none",
                    strokeOpacity: 1,
                  }}
                />
                {`${arrayData[0]?.AICAT4}` ||
                `${arrayData[0]?.AICAT5}` === "Y" ? (
                  <DrawCircleMotion d="M105 74.175h10.567 0 6.615 3.307 6.615 6.614v32.374" />
                ) : (
                  <svg
                    style={{ color: "red", position: "absolute" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="255"
                    height="150"
                    fill="currentColor"
                    class="bi bi-x"
                    viewBox="0 -91 16 200"
                  >
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 
                  .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 
                  0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                      fill="red"
                    ></path>
                  </svg>
                )}
                <path
                  d="M-4.046 52.524h60.868 0 5.556 2.778 5.556 5.556v13.844"
                  style={{
                    fill: "none",
                    fillRule: "evenodd",
                    stroke:
                      `${arrayData[0]?.AICAT4}` === "Y" ? "#008000" : "gray",
                    strokeWidth: 0.573524,
                    strokeLinecap: "butt",
                    strokeLinejoin: "miter",
                    strokeDasharray: "none",
                    strokeOpacity: 1,
                  }}
                />
                {`${arrayData[0]?.AICAT4}` === "Y" ? (
                  <DrawCircleMotion d="M-4.046 52.524h60.868 0 5.556 2.778 5.556 5.556v13.844" />
                ) : (
                  <svg
                    style={{ color: "red", position: "absolute" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="105"
                    x="15"
                    fill="currentColor"
                    class="bi bi-x"
                    viewBox="-10 0 50 16"
                  >
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 
                  .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 
                  0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                      fill="red"
                    ></path>
                  </svg>
                )}

                <image
                  className={styles.hoveringEffect01}
                  href={images.inverter}
                  width={40}
                  height={50}
                  x={65.605}
                  y={59.184}
                  onClick={() => {
                    setType("Inverter");
                    graph("Inverter");
                    popUp("Inverter");
                    setIsHovering(true);
                  }}
                  preserveAspectRatio="none"
                />
                <image
                  className={styles.hoveringEffect01}
                  href={images.grid}
                  width={40.386}
                  height={60.855}
                  x={230}
                  y={-8}
                  onClick={() => {
                    setType("Grid");
                    graph("Grid");
                    popUp("Grid");
                    setIsHovering(true);
                  }}
                  preserveAspectRatio="none"
                />
                <image
                  className={styles.hoveringEffect01}
                  href={images.elecPanel}
                  width={30.386}
                  height={30.855}
                  x={132}
                  y={101}
                  preserveAspectRatio="none"
                />
                <DrawConnector
                  strokeWidth="0.7"
                  toggle="false"
                  color={`${arrayData[0]?.AICAT2}` === "Y" ? "#008000" : "gray"}
                  d="M153 51.0h82,M153.334 51.388v51.5"
                />
                {`${arrayData[0]?.AICAT2}` === "Y" ? (
                  <DrawCircleMotion d="M234.947,51.04L234.947,51.75L153.689,51.75,M153.334 51.388v-.355 51.5" />
                ) : (
                  <svg
                    style={{ color: "red", position: "absolute" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="380"
                    height="105"
                    fill="currentColor"
                    class="bi bi-x"
                    viewBox="0 -40.5 16 100"
                  >
                    <path
                      d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 
                  .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 
                  0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                      fill="red"
                    ></path>
                  </svg>
                )}
                <g>
                  <DrawConnector
                    strokeWidth="0.7"
                    toggle="false"
                    color={
                      `${arrayData[0]?.AICAT1}` === "Y" ? "#008000" : "gray"
                    }
                    d="M221,115.814L159.882,115.814"
                  />
                  {`${arrayData[0]?.AICAT1}` === "Y" ? (
                    <DrawCircleMotion d="M221,115.814L159.882,115.814" />
                  ) : (
                    <svg
                      style={{ color: "red", position: "absolute" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="410"
                      height="260"
                      x="-15"
                      y="-14"
                      fill="currentColor"
                      class="bi bi-x"
                      viewBox="0 -142 16 300"
                    >
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 
                  .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 
                  0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                        fill="red"
                      ></path>
                    </svg>
                  )}
                  {arrayData[0]?.AICAT5 !== "N" ||
                    (arrayData[0]?.AICAT5 === "N" && myBattery?.length > 0 && (
                      <DrawConnector
                        strokeWidth="0.7"
                        toggle="false"
                        color={
                          `${arrayData[0]?.AICAT5}` === "N" &&
                          myBattery?.length > 0
                            ? "gray"
                            : "#008000"
                        }
                        d="M98,21.814L97.882,60.814"
                      />
                    ))}
                  {/* <DrawCircleMotion d="M98,21.814L97.882,60.814" /> */}
                  {arrayData[0]?.AICAT5 === "C" && (
                    <DrawCircleMotion d="M98,61.814L97.882,20.814" />
                  )}

                  {arrayData[0]?.AICAT5 === "D" && (
                    <DrawCircleMotion d="M98,21.814L97.882,60.814" />
                  )}

                  {myBattery?.length > 0 && arrayData[0]?.AICAT5 === "N" && (
                    <svg
                      style={{ color: "red", position: "absolute" }}
                      xmlns="http://www.w3.org/2000/svg"
                      width="380"
                      height="105"
                      fill="currentColor"
                      class="bi bi-x"
                      viewBox="0 -30 191 100"
                    >
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 
                .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 
                0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                        fill="red"
                      ></path>
                    </svg>
                  )}

                  {myBattery?.length > 0 && (
                    <image
                      className={styles.hoveringEffect01}
                      href={images.storage}
                      width={40}
                      height={35}
                      x={80}
                      y={-10}
                      preserveAspectRatio="none"
                    />
                  )}
                  <image
                    className={styles.hoveringEffect01}
                    onClick={() => {
                      setType("Generator");
                      graph("Generator");
                      popUp("Generator");
                      setIsHovering(true);
                    }}
                    href={images.generator}
                    width={54.319}
                    height={29.804}
                    x={216.8}
                    y={103.566}
                    preserveAspectRatio="none"
                  />
                </g>
                <RectInfo
                  x="270"
                  y="105"
                  width="40"
                  height="25"
                  title="Generator"
                  info={arrayData[0]?.AIGENPOWER + "kVAr"}
                  info2={arrayData[0]?.AIGENRPOWER + "kVAr"}
                />
                <RectInfo
                  x="-100"
                  y="120"
                  width="45"
                  height="25"
                  title="Load consumption"
                  info={arrayData[0]?.AILOADPOWER + "kW"}
                  info2=""
                />
                <RectInfo
                  x="-100"
                  y="10"
                  width="45"
                  height="25"
                  title="Solar production"
                  info={arrayData[0]?.AISOLARPOWER + "kW"}
                  info2=""
                />
                <RectInfo
                  x="270"
                  y="10"
                  width="40"
                  height="25"
                  title="EDL"
                  info={arrayData[0]?.AIGRIDPOWER + "KW"}
                  info2=""
                />
                <RectInfo
                  x="25"
                  y="70"
                  width="40"
                  height="25"
                  title="Inverter"
                  info={arrayData[0]?.AIINVERTERPOWER + "KW"}
                  info2=""
                />
                {arrayData[0]?.AICAT5 !== "N" && (
                  <RectInfo
                    x="125"
                    y="-5"
                    width="40"
                    height="25"
                    title="Storage"
                    info={
                      arrayData[0]?.AICAT5 === "C" ? "Charging" : "Discharging"
                    }
                    info2={arrayData[0]?.AISTORAGEPOWER + "KW"}
                  />
                )}
                <image
                  className={styles.hoveringEffect01}
                  href={images.bldg}
                  width={70.498}
                  height={64.801}
                  x={-69.838}
                  y={102.436}
                  onClick={() => {
                    setType("Load");
                    graph("Load");
                    popUp("Load");
                    setIsHovering(true);
                  }}
                  preserveAspectRatio="none"
                />
                <image
                  className={styles.hoveringEffect01}
                  href={images.panels}
                  width={73.449}
                  height={59.168}
                  x={-69.858}
                  y={-4.802}
                  preserveAspectRatio="none"
                />
              </g>
            </svg>
          </Box>
        </Box>
      )}
      {arrayData.length > 0 && !isLoading && !httpError && isHovering && (
        <Box className={styles.details} display="flex" flexDirection="row">
          <Grid container height={"auto"}>
            {graphData.length >= 0 && !isPopLoading && !httpPopError && (
              <Grid
                item
                xs={11}
                container
                sx={{
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {genStatus?.map((status) => {
                  return (
                    <Grid item xs={4} container key={status.AHDESC1}>
                      <Grid item xs={11}>
                        <Grid
                          sx={{ backgroundColor: "#064987", color: "white" }}
                        >
                          {status.AHDESC1}
                        </Grid>
                        <Grid
                          sx={{
                            color:
                              status.AJSTATUS === "Y" ? "#559446" : "#dc2020",
                            fontWeight: "bold",
                            marginY: "1%",
                          }}
                        >
                          {status.AJSTATUS === "Y" ? "ON" : "OFF"}
                        </Grid>
                        <Grid container>
                          <Grid item xs={5.9} sx={popUpStyle.numbers}>
                            {status.AJFREQ.toFixed(1)}{" "}
                          </Grid>
                          <Grid item xs={0.2}></Grid>
                          <Grid item xs={5.9} sx={popUpStyle.units}>
                            Hz
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5.9} sx={popUpStyle.numbers}>
                            {status.AJVOLT.toFixed(1)}{" "}
                          </Grid>
                          <Grid item xs={0.2}></Grid>
                          <Grid item xs={5.9} sx={popUpStyle.units}>
                            V
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5.9} sx={popUpStyle.numbers}>
                            {status.AJACTIVEPOWER.toFixed(1)}{" "}
                          </Grid>
                          <Grid item xs={0.2}></Grid>
                          <Grid item xs={5.9} sx={popUpStyle.units}>
                            KW
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5.9} sx={popUpStyle.numbers}>
                            {status.AJLOADPERC.toFixed(1)}{" "}
                          </Grid>
                          <Grid item xs={0.2}></Grid>
                          <Grid item xs={5.9} sx={popUpStyle.units}>
                            %
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid item xs={5.9} sx={popUpStyle.numbers}>
                            {status.AJREACTPOWER.toFixed(1)}{" "}
                          </Grid>
                          <Grid item xs={0.2}></Grid>
                          <Grid item xs={5.9} sx={popUpStyle.units}>
                            KVAr
                          </Grid>
                        </Grid>
                        <Grid sx={{ marginTop: "10px", height: "25%" }}>
                          <Box
                            component="img"
                            src={
                              status.AJSTATUS === "Y" ? images.on : images.off
                            }
                            alt=""
                            width={"50%"}
                            height={"160%"}
                            className={styles.imgg}
                            style={{ overlay: "black" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={1}></Grid>
                    </Grid>
                  );
                })}
              </Grid>
            )}
            {graphData.length >= 0 && isPopLoading && !httpPopError && (
              <Grid
                item
                xs={11}
                container
                sx={{
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className={styles2.loader}>
                  <div className={styles2.spinner}></div>
                </div>
              </Grid>
            )}
            {graphData.length >= 0 && !isPopLoading && httpPopError && (
              <Grid
                item
                xs={11}
                container
                sx={{
                  padding: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {httpPopError}
              </Grid>
            )}
            <Grid item xs={1} width={"100%"}>
              <Button onClick={handleMouseOut} sx={{ height: "20%" }}>
                <CloseIcon
                  sx={{ width: "80%", height: "80%", color: "#959595" }}
                />
              </Button>
              <Grid
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  height: "75%",
                  width: "100%",
                }}
              >
                <Link
                  to={`../${type.toLowerCase()}`}
                  style={linkStyle}
                  state={type}
                >
                  Dashboard
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
      {arrayData.length >= 0 && isLoading && !httpError && (
        <Box sx={{ textAlign: "center", marginTop: "100px" }}>
          <div className={styles2.loader}>
            <div className={styles2.spinner}></div>
          </div>
        </Box>
      )}
      {arrayData.length >= 0 && !isLoading && httpError && (
        <Box sx={{ textAlign: "center", marginTop: "100px", color: "red" }}>
          {httpError}
        </Box>
      )}
    </Box>
  );
}
export default SvgComponent001;
