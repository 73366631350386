import React, { useState } from "react";
import {
  Button,
  Select,
  FormControl,
  InputLabel,
  Box,
  Grid,
  MenuItem,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import httpReq from "../../Services/http/httpReq";
import { decodeToken } from "react-jwt";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Spline from "./components/Spline";
import TableViewIcon from "@mui/icons-material/TableView";
import Table from "./table";
import Consumption001 from "./consumption/Consumption";
import { useSelector } from "react-redux";
import WeeklyConsumption from "./consumption/WeeklyConsumption";
import MYConsumption from "./consumption/MYConsumption";
import styles2 from "../dashboard/components/dash.module.scss";

const Details0010 = () => {
  const location = useLocation();
  const type = location.state;
  const [graphData, setGraphData] = useState([]);
  const [showValues, setShowValues] = useState({
    generatorId: 0,
    value: "power",
  });

  const userCurrentSystem = useSelector(
    (state) => state.menu.userCurrentSystem
  );

  const userCurrentArray = useSelector((state) => state.menu.userCurrentArray);

  const myDropdown = userCurrentArray?.[0]?.filter(
    (array) => array.AHTYPE.toLowerCase() === type.toLowerCase()
  );

  const updateGeneratorId = (i) => {
    setShowValues({
      ...showValues,
      generatorId: i,
    });
    setGraphData([]);
  };

  const updateValue = (e) => {
    setShowValues({
      ...showValues,
      value: e,
    });
  };

  const [fromDate, setFromDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState("");
  const [aPDataTable, setAPDataTable] = React.useState(false);
  const [rPDataTable, setRPDataTable] = React.useState(false);
  const [apPDataTable, setApPDataTable] = React.useState(false);
  const [fDataTable, setFDataTable] = React.useState(false);
  const [vDataTable, setVDataTable] = React.useState(false);
  const [cDataTable, setCDataTable] = React.useState(false);
  function openAPData() {
    setAPDataTable(!aPDataTable);
  }
  function openRPData() {
    setRPDataTable(!rPDataTable);
  }
  function openApPData() {
    setApPDataTable(!apPDataTable);
  }
  function openFData() {
    setFDataTable(!fDataTable);
  }
  function openVData() {
    setVDataTable(!vDataTable);
  }
  function openCData() {
    setCDataTable(!cDataTable);
  }

  const activePowerColumns = [
    { field: "date", headerName: "Date", width: 200 },
    { field: "activePowerP1", headerName: "Active Power Phase 1", width: 150 },
    { field: "activePowerP2", headerName: "Active Power Phase 2", width: 150 },
    { field: "activePowerP3", headerName: "Active Power Phase 3", width: 150 },
  ];
  const reactivePowerColumns = [
    { field: "date", headerName: "Date", width: 200 },
    {
      field: "reactivePowerP1",
      headerName: "Reactive Power Phase 1",
      width: 150,
    },
    {
      field: "reactivePowerP2",
      headerName: "Reactive Power Phase 2",
      width: 150,
    },
    {
      field: "reactivePowerP3",
      headerName: "Reactive Power Phase 3",
      width: 150,
    },
  ];
  const apparentPowerColumns = [
    { field: "date", headerName: "Date", width: 200 },
    {
      field: "apparentPowerP1",
      headerName: "Apparent Power Phase 1",
      width: 150,
    },
    {
      field: "apparentPowerP2",
      headerName: "Apparent Power Phase 2",
      width: 150,
    },
    {
      field: "apparentPowerP3",
      headerName: "Apparent Power Phase 3",
      width: 150,
    },
  ];
  const frequencyColumns = [
    { field: "date", headerName: "Date", width: 200 },
    { field: "frequencyP1", headerName: "Frequency Phase 1", width: 150 },
    { field: "frequencyP2", headerName: "Frequency Phase 2", width: 150 },
    { field: "frequencyP3", headerName: "Frequency Phase 3", width: 150 },
  ];
  const voltageColumns = [
    { field: "date", headerName: "Date", width: 200 },
    { field: "voltageP1", headerName: "Voltage Phase 1", width: 150 },
    { field: "voltageP2", headerName: "Voltage Phase 2", width: 150 },
    { field: "voltageP3", headerName: "Voltage Phase 3", width: 150 },
  ];
  const currentColumns = [
    { field: "date", headerName: "Date", width: 200 },
    { field: "currentP1", headerName: "Current Phase 1", width: 150 },
    { field: "currentP2", headerName: "Current Phase 2", width: 150 },
    { field: "currentP3", headerName: "Current Phase 3", width: 150 },
  ];

  var i;
  var j;
  var k;
  var l;
  var m;
  var n;

  var _activePowernewData = [];
  var _reactivePowernewData = [];
  var _apparentPowernewData = [];
  var _frequencynewData = [];
  var _voltagenewData = [];
  var _currentnewData = [];

  var _totalGenPower = 0;
  var _totalGridPower = 0;
  var _totalInverterPower = 0;

  for (i = 0; i < graphData[0]?.[0].length; i++) {
    _activePowernewData[i] = {
      id: Math.random(),
      date: moment(graphData[0][0][i].AJTIMESTAMP).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      activePowerP1: graphData[0][0][i].AJVALUE,
      activePowerP2: graphData[0][1][i].AJVALUE,
      activePowerP3: graphData[0][2][i].AJVALUE,
    };
    // _totalGenPower = _totalGenPower + graphData[i].AIGENPOWER;
    // _totalGridPower = _totalGenPower + graphData[i].AIGRIDPOWER;
    // _totalInverterPower = _totalGenPower + graphData[i].AIINVERTERPOWER;
  }
  for (j = 0; j < graphData[0]?.[0].length; j++) {
    _reactivePowernewData[j] = {
      id: Math.random(),
      date: moment(graphData[0][3][j].AJTIMESTAMP).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      reactivePowerP1: graphData[0][3][j].AJVALUE,
      reactivePowerP2: graphData[0][4][j].AJVALUE,
      reactivePowerP3: graphData[0][5][j].AJVALUE,
    };
  }
  for (k = 0; k < graphData[0]?.[0].length; k++) {
    _apparentPowernewData[k] = {
      id: Math.random(),
      date: moment(graphData[0][6][k].AJTIMESTAMP).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      apparentPowerP1: graphData[0][6][k].AJVALUE,
      apparentPowerP2: graphData[0][7][k].AJVALUE,
      apparentPowerP3: graphData[0][8][k].AJVALUE,
    };
  }
  for (l = 0; l < graphData[0]?.[0].length; l++) {
    _frequencynewData[l] = {
      id: Math.random(),
      date: moment(graphData[0][9][l].AJTIMESTAMP).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      frequencyP1: graphData[0][9][l].AJVALUE,
      frequencyP2: graphData[0][10][l].AJVALUE,
      frequencyP3: graphData[0][11][l].AJVALUE,
    };
  }
  for (m = 0; m < graphData[0]?.[0].length; m++) {
    _voltagenewData[m] = {
      id: Math.random(),
      date: moment(graphData[0][0][m].AJTIMESTAMP).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      voltageP1: graphData[0][12][m].AJVALUE,
      voltageP2: graphData[0][13][m].AJVALUE,
      voltageP3: graphData[0][14][m].AJVALUE,
    };
  }
  for (n = 0; n < graphData[0]?.[0].length; n++) {
    _currentnewData[n] = {
      id: Math.random(),
      date: moment(graphData[0][0][n].AJTIMESTAMP).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      currentP1: graphData[0][15][n].AJVALUE,
      currentP2: graphData[0][16][n].AJVALUE,
      currentP3: graphData[0][17][n].AJVALUE,
    };
  }

  const activePowerLabels = [
    graphData?.[0]?.[0]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
    graphData?.[0]?.[1]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
    graphData?.[0]?.[2]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
  ].flat();

  const reactivePowerLabels = [
    graphData?.[0]?.[3]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
    graphData?.[0]?.[4]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
    graphData?.[0]?.[5]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
  ].flat();

  const apparentPowerLabels = [
    graphData?.[0]?.[6]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
    graphData?.[0]?.[7]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
    graphData?.[0]?.[8]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
  ].flat();

  const frequencyLabels = [
    graphData?.[0]?.[9]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
    graphData?.[0]?.[10]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
    graphData?.[0]?.[11]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
  ].flat();

  const voltageLabels = [
    graphData?.[0]?.[12]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
    graphData?.[0]?.[13]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
    graphData?.[0]?.[14]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
  ].flat();

  const currentLabels = [
    graphData?.[0]?.[15]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
    graphData?.[0]?.[16]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
    graphData?.[0]?.[17]?.map((item) =>
      moment(item.AJTIMESTAMP).format("YYYY-MM-DD HH:mm:ss")
    ),
  ].flat();

  const activePower = {
    labels: activePowerLabels.filter(
      (item, index) => activePowerLabels.indexOf(item) === index
    ),
    datasets: [
      {
        label: graphData?.[0]?.[0]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[0]?.map((value) => value.AJVALUE),
      },
      {
        label: graphData?.[0]?.[1]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(10,95,255,0.4)",
        borderColor: "rgba(10,95,255,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(10,95,255,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(10,95,255,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[1]?.map((value) => value.AJVALUE),
      },
      {
        label: graphData?.[0]?.[2]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(255,0,0,0.4)",
        borderColor: "rgba(255,0,0,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(255,0,0,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(255,0,0,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[2]?.map((value) => value.AJVALUE),
      },
    ],
  };

  const reactivePower = {
    labels: reactivePowerLabels.filter(
      (item, index) => reactivePowerLabels.indexOf(item) === index
    ),
    datasets: [
      {
        label: graphData?.[0]?.[3]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[3]?.map((value) => value.AJVALUE),
      },
      {
        label: graphData?.[0]?.[4]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(10,95,255,0.4)",
        borderColor: "rgba(10,95,255,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(10,95,255,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(10,95,255,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[4]?.map((value) => value.AJVALUE),
      },
      {
        label: graphData?.[0]?.[5]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(255,0,0,0.4)",
        borderColor: "rgba(255,0,0,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(255,0,0,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(255,0,0,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[5]?.map((value) => value.AJVALUE),
      },
    ],
  };

  const apparentPower = {
    labels: apparentPowerLabels.filter(
      (item, index) => apparentPowerLabels.indexOf(item) === index
    ),
    datasets: [
      {
        label: graphData?.[0]?.[6]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[6]?.map((value) => value.AJVALUE),
      },
      {
        label: graphData?.[0]?.[7]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(10,95,255,0.4)",
        borderColor: "rgba(10,95,255,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(10,95,255,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(10,95,255,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[7]?.map((value) => value.AJVALUE),
      },
      {
        label: graphData?.[0]?.[8]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(255,0,0,0.4)",
        borderColor: "rgba(255,0,0,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(255,0,0,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(255,0,0,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[8]?.map((value) => value.AJVALUE),
      },
    ],
  };

  const frequency = {
    labels: frequencyLabels.filter(
      (item, index) => frequencyLabels.indexOf(item) === index
    ),
    datasets: [
      {
        label: graphData?.[0]?.[9]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[9]?.map((value) => value.AJVALUE),
      },
      {
        label: graphData?.[0]?.[10]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(10,95,255,0.4)",
        borderColor: "rgba(10,95,255,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(10,95,255,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(10,95,255,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[10]?.map((value) => value.AJVALUE),
      },
      {
        label: graphData?.[0]?.[11]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(255,0,0,0.4)",
        borderColor: "rgba(255,0,0,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(255,0,0,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(255,0,0,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[11]?.map((value) => value.AJVALUE),
      },
    ],
  };

  const voltage = {
    labels: voltageLabels.filter(
      (item, index) => voltageLabels.indexOf(item) === index
    ),
    datasets: [
      {
        label: graphData?.[0]?.[12]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[12]?.map((value) => value.AJVALUE),
      },
      {
        label: graphData?.[0]?.[13]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(10,95,255,0.4)",
        borderColor: "rgba(10,95,255,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(10,95,255,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(10,95,255,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[13]?.map((value) => value.AJVALUE),
      },
      {
        label: graphData?.[0]?.[14]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(255,0,0,0.4)",
        borderColor: "rgba(255,0,0,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(255,0,0,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(255,0,0,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[14]?.map((value) => value.AJVALUE),
      },
    ],
  };

  const current = {
    labels: currentLabels.filter(
      (item, index) => currentLabels.indexOf(item) === index
    ),
    datasets: [
      {
        label: graphData?.[0]?.[15]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(75,192,192,0.4)",
        borderColor: "rgba(75,192,192,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(75,192,192,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(75,192,192,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[15]?.map((value) => value.AJVALUE),
      },
      {
        label: graphData?.[0]?.[16]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(10,95,255,0.4)",
        borderColor: "rgba(10,95,255,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(10,95,255,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(10,95,255,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[16]?.map((value) => value.AJVALUE),
      },
      {
        label: graphData?.[0]?.[17]?.[0]?.AJLABEL,
        fill: false,
        lineTension: 0.1,
        backgroundColor: "rgba(255,0,0,0.4)",
        borderColor: "rgba(255,0,0,1)",
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(255,0,0,1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(255,0,0,1)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 1,
        pointHitRadius: 10,
        data: graphData?.[0]?.[17]?.map((value) => value.AJVALUE),
      },
    ],
  };

  const equipid = myDropdown?.[showValues.generatorId].AHEQUIPID;

  function graph(type1, equipid) {
    var x = {
      SYSTEMID: userCurrentSystem?.[0].AGSYSTEMID,
      FROMDATE: fromDate,
      TODATE: toDate,
      TYPE: type1,
      EQUIPID: equipid,
    };
    httpReq.post("datareq", "ACdash01", x).then(
      (response) => {
        var _decodedResponse = decodeToken(response.data);
        if (_decodedResponse.reply === "ACdash01") {
          var ACdash01 = JSON.parse(_decodedResponse.data);
          setGraphData(ACdash01);
        }
        setIsLoading(false);
      },
      (error) => {
        console.log("error request");
        setHttpError(`${error.message}`);
      }
    );
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ width: "100%" }}>
      <DemoContainer
        components={["DateField"]}
        sx={{ width: "100%", overflow: "hidden" }}
      >
        <Box sx={{ width: "100%" }}>
          {(type === "generator" || type === "Generator") && (
            <Grid container sx={{ display: "flex", justifyContent: "center" }}>
              <Grid item xs={12}>
                <WeeklyConsumption />
              </Grid>
              <Grid item xs={12}>
                <MYConsumption />
              </Grid>
            </Grid>
          )}
          <Box
            sx={{
              padding: "0px",
              width: "100%",
              minHeight: "900px",
              display: "flex",
              justifyContent: "center",
            }}
            item
            xs={12}
            container
          >
            <Grid container>
              <Grid
                container
                item
                textAlign={"center"}
                display={"flex"}
                alignItems={"center"}
                marginTop={"-10px"}
                marginBottom={"-10px"}
                sx={{
                  height: "100px",
                  backgroundColor: "#b5b6ef69",
                  width: "100%",
                }}
                xs={12}
              >
                <Grid item xs={0.2}></Grid>
                <Grid item xs={1.9}>
                  <FormControl
                    sx={{
                      width: "100%",
                      color: "black",
                      marginTop: "5px",
                      ".css-9425fu-MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                        color: "black",
                      },
                      ".css-1faml0z-MuiSvgIcon-root-MuiSelect-icon": {
                        color: "black",
                      },
                      ".css-nqlg3w": {
                        borderColor: "black",
                      },
                      ".css-8mig72": {
                        color: "black",
                      },
                    }}
                  >
                    <InputLabel sx={{ color: "black" }}>Systems</InputLabel>
                    <Select
                      label="Systems"
                      width="100%"
                      sx={{ color: "black" }}
                      onChange={(e) => updateGeneratorId(e.target.value)}
                      value={showValues.generatorId}
                    >
                      {myDropdown?.map((menu, i) => (
                        <MenuItem
                          key={i}
                          value={i}
                          onClick={() => updateGeneratorId(i)}
                        >
                          {menu?.AHDESC1}{" "}
                          {menu?.AHDESC1.includes("Generator") ? "" : i + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={0.3}></Grid>
                <Grid item xs={1.9}>
                  <FormControl
                    sx={{
                      width: "100%",
                      color: "black",
                      marginTop: "5px",
                      ".css-9425fu-MuiOutlinedInput-notchedOutline": {
                        borderColor: "black",
                        color: "black",
                      },
                      ".css-1faml0z-MuiSvgIcon-root-MuiSelect-icon": {
                        color: "black",
                      },
                      ".css-nqlg3w": {
                        borderColor: "black",
                      },
                      ".css-8mig72": {
                        color: "black",
                      },
                    }}
                  >
                    <InputLabel sx={{ color: "black" }}>Type</InputLabel>
                    <Select
                      label="Type"
                      width="100%"
                      sx={{ color: "black" }}
                      onChange={(e) => updateValue(e.target.value)}
                      value={showValues.value}
                    >
                      <MenuItem
                        value="power"
                        onClick={() => updateValue("power")}
                        defaultChecked
                      >
                        Power
                      </MenuItem>
                      <MenuItem
                        value="frequency"
                        onClick={() => updateValue("frequency")}
                      >
                        Frequency
                      </MenuItem>
                      <MenuItem
                        value="voltage"
                        onClick={() => updateValue("voltage")}
                      >
                        Voltage
                      </MenuItem>
                      <MenuItem
                        value="current"
                        onClick={() => updateValue("current")}
                      >
                        Current
                      </MenuItem>
                      <MenuItem
                        value="consumption"
                        onClick={() => updateValue("consumption")}
                      >
                        Consumption
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={0.3}></Grid>
                <Grid item xs={0.5} textAlign={"center"}>
                  From
                </Grid>
                <Grid item xs={2.1}>
                  <DatePicker
                    value={dayjs(fromDate)}
                    maxDate={dayjs(toDate)}
                    onChange={(newValue, date) => {
                      setFromDate(
                        moment(newValue.unix() * 1000).format("YYYY-MM-DD")
                      );
                    }}
                    sx={{
                      ".css-9425fu-MuiOutlinedInput-notchedOutline": {
                        color: "black !important",
                        borderColor: "black !important",
                      },
                      ".css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input": {
                        color: "black !important",
                        borderColor: "black !important",
                      },
                      ".css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          color: "black !important",
                        },
                      "fieldset.MuiOutlinedInput-notchedOutline.css-9425fu-MuiOutlinedInput-notchedOutline:hover":
                        {
                          borderColor: "black !important",
                        },
                      ".css-nqlg3w": {
                        borderColor: "black",
                      },
                      ".css-putrnr": {
                        color: "black",
                      },
                    }}
                    components={{
                      OpenPickerIcon: () => {
                        return <CalendarMonthIcon sx={{ color: "#064987" }} />;
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: "color: black",
                        input: "color:black",
                      },
                    }}
                    inputFormat="YYYY-MM-DD"
                    PaperProps={{
                      sx: {
                        position: "fixed",
                        left: { xs: -130, lg: -211 },
                        width: { lg: "471px" },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={0.5}>
                  To
                </Grid>
                <Grid item xs={2.1}>
                  <DatePicker
                    value={dayjs(toDate)}
                    minDate={dayjs(fromDate)}
                    maxDate={dayjs(Date.now())}
                    onChange={(newValue) => {
                      setToDate(
                        moment(newValue.unix() * 1000).format("YYYY-MM-DD")
                      );
                    }}
                    sx={{
                      ".css-9425fu-MuiOutlinedInput-notchedOutline": {
                        color: "black !important",
                        borderColor: "black !important",
                      },
                      ".css-p51h6s-MuiInputBase-input-MuiOutlinedInput-input": {
                        color: "black !important",
                        borderColor: "black !important",
                      },
                      ".css-1h9uykw-MuiInputBase-input-MuiOutlinedInput-input":
                        {
                          color: "black !important",
                        },
                      "fieldset.MuiOutlinedInput-notchedOutline.css-9425fu-MuiOutlinedInput-notchedOutline:hover":
                        {
                          borderColor: "black !important",
                        },
                      ".css-nqlg3w": {
                        borderColor: "black",
                      },
                      ".css-putrnr": {
                        color: "black",
                      },
                    }}
                    components={{
                      OpenPickerIcon: () => {
                        return <CalendarMonthIcon sx={{ color: "#064987" }} />;
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: "color: black",
                        input: "color:black",
                      },
                    }}
                    inputFormat="YYYY-MM-DD"
                    PaperProps={{
                      sx: {
                        position: "fixed",
                        left: { xs: -130, lg: -211 },
                        width: { lg: "471px" },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={0.5}></Grid>
                <Grid
                  item
                  xs={1.5}
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Button
                    sx={{
                      width: "100%",
                      backgroundColor: "#3a89c6",
                      color: "white",
                    }}
                    onClick={() => {
                      graph(type, equipid);
                    }}
                  >
                    Show
                  </Button>
                </Grid>
              </Grid>
              {graphData.length > 0 && !isLoading && !httpError && (
                <Grid
                  xs={12}
                  item
                  container
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  {showValues.value === "power" && (
                    <Grid
                      container
                      sx={{
                        height: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginY: "3%",
                      }}
                    >
                      <Grid
                        item
                        xs={11}
                        padding="3%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-evenly"
                        alignItems={"center"}
                        container
                        height={"60%"}
                        boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                      >
                        <Grid container item xs={12}>
                          <Grid
                            container
                            item
                            xs={12}
                            sx={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Grid
                              item
                              xs={10}
                              sx={{
                                color: "#064987",
                                paddingLeft: "5%",
                              }}
                            >
                              Active Power
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "right" }}>
                              <Button
                                sx={{ height: "100%" }}
                                onClick={openAPData}
                              >
                                <TableViewIcon sx={{ color: "#064987" }} />
                              </Button>
                            </Grid>
                          </Grid>
                          {!aPDataTable ? (
                            <Grid item xs={12}>
                              <Spline
                                data={activePower}
                                width="100%"
                                height="100%"
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <Table
                                columns={activePowerColumns}
                                _newData={_activePowernewData}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      {/* <Grid item xs={0.3}></Grid> */}
                      <Grid
                        item
                        xs={11}
                        padding="3%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-evenly"
                        alignItems={"center"}
                        container
                        height={"60%"}
                        marginY={"3%"}
                        boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                      >
                        <Grid container item xs={12}>
                          <Grid
                            container
                            item
                            xs={12}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Grid
                              item
                              xs={10}
                              sx={{
                                color: "#064987",
                                paddingLeft: "5%",
                              }}
                            >
                              Reactive Power
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "right" }}>
                              <Button
                                sx={{ height: "100%" }}
                                onClick={openRPData}
                              >
                                <TableViewIcon sx={{ color: "#064987" }} />
                              </Button>
                            </Grid>
                          </Grid>
                          {!rPDataTable ? (
                            <Grid item xs={12}>
                              <Spline
                                data={reactivePower}
                                width="100%"
                                height="100%"
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <Table
                                columns={reactivePowerColumns}
                                _newData={_reactivePowernewData}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      {/* <Grid item xs={0.3}></Grid> */}
                      <Grid
                        item
                        xs={11}
                        padding="3%"
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-evenly"
                        alignItems={"center"}
                        container
                        height={"60%"}
                        boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                      >
                        <Grid container item xs={12}>
                          <Grid
                            container
                            item
                            xs={12}
                            sx={{ display: "flex", alignItems: "center" }}
                          >
                            <Grid
                              item
                              xs={10}
                              sx={{
                                color: "#064987",
                                paddingLeft: "5%",
                              }}
                            >
                              Apparent Power
                            </Grid>
                            <Grid item xs={2} sx={{ textAlign: "right" }}>
                              <Button
                                sx={{ height: "100%" }}
                                onClick={openApPData}
                              >
                                <TableViewIcon sx={{ color: "#064987" }} />
                              </Button>
                            </Grid>
                          </Grid>
                          {!apPDataTable ? (
                            <Grid item xs={12}>
                              <Spline
                                data={apparentPower}
                                width="100%"
                                height="100%"
                              />
                            </Grid>
                          ) : (
                            <Grid item xs={12}>
                              <Table
                                columns={apparentPowerColumns}
                                _newData={_apparentPowernewData}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {(showValues.value === "frequency" ||
                    showValues.value === "voltage" ||
                    showValues.value === "current") && (
                    <Grid
                      container
                      sx={{
                        height: "50%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      xs={12}
                    >
                      {showValues.value === "frequency" && (
                        <Grid
                          item
                          xs={11}
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-evenly"
                          alignItems={"center"}
                          container
                          height={"80%"}
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                            padding="3%"
                          >
                            <Grid
                              container
                              item
                              xs={12}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Grid
                                item
                                xs={10}
                                sx={{
                                  color: "#064987",
                                  paddingLeft: "5%",
                                }}
                              >
                                Frequency
                              </Grid>
                              <Grid item xs={2} sx={{ textAlign: "right" }}>
                                <Button
                                  sx={{ height: "100%" }}
                                  onClick={openFData}
                                >
                                  <TableViewIcon sx={{ color: "#064987" }} />
                                </Button>
                              </Grid>
                            </Grid>
                            {!fDataTable ? (
                              <Grid item xs={12}>
                                <Spline
                                  data={frequency}
                                  width="100%"
                                  height="100%"
                                />
                              </Grid>
                            ) : (
                              <Grid item xs={12}>
                                <Table
                                  columns={frequencyColumns}
                                  _newData={_frequencynewData}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}
                      {showValues.value === "voltage" && (
                        <Grid
                          item
                          xs={11}
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-evenly"
                          alignItems={"center"}
                          container
                          height={"80%"}
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                            padding="3%"
                          >
                            <Grid
                              container
                              item
                              xs={12}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Grid
                                item
                                xs={10}
                                sx={{
                                  color: "#064987",
                                  paddingLeft: "5%",
                                }}
                              >
                                Voltage
                              </Grid>
                              <Grid item xs={2} sx={{ textAlign: "right" }}>
                                <Button
                                  sx={{ height: "100%" }}
                                  onClick={openVData}
                                >
                                  <TableViewIcon sx={{ color: "#064987" }} />
                                </Button>
                              </Grid>
                            </Grid>
                            {!vDataTable ? (
                              <Grid item xs={12}>
                                <Spline
                                  data={voltage}
                                  width="100%"
                                  height="100%"
                                />
                              </Grid>
                            ) : (
                              <Grid item xs={12}>
                                <Table
                                  columns={voltageColumns}
                                  _newData={_voltagenewData}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}
                      {showValues.value === "current" && (
                        <Grid
                          item
                          xs={11}
                          display="flex"
                          flexDirection="row"
                          justifyContent="space-evenly"
                          alignItems={"center"}
                          container
                          height={"80%"}
                        >
                          <Grid
                            container
                            item
                            xs={12}
                            boxShadow=" 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
                            padding="3%"
                          >
                            <Grid
                              container
                              item
                              xs={12}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Grid
                                item
                                xs={10}
                                sx={{
                                  color: "#064987",
                                  paddingLeft: "5%",
                                }}
                              >
                                Current
                              </Grid>
                              <Grid item xs={2} sx={{ textAlign: "right" }}>
                                <Button
                                  sx={{ height: "100%" }}
                                  onClick={openCData}
                                >
                                  <TableViewIcon sx={{ color: "#064987" }} />
                                </Button>
                              </Grid>
                            </Grid>
                            {!cDataTable ? (
                              <Grid item xs={12}>
                                <Spline
                                  data={current}
                                  width="100%"
                                  height="100%"
                                />
                              </Grid>
                            ) : (
                              <Grid item xs={12}>
                                <Table
                                  columns={currentColumns}
                                  _newData={_currentnewData}
                                />
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  )}
                  {showValues.value === "consumption" && (
                    <Grid
                      container
                      sx={{
                        height: "80%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginY: "3%",
                      }}
                      xs={12}
                    >
                      <Consumption001 />
                    </Grid>
                  )}
                </Grid>
              )}
              {isLoading && !httpError && (
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50%",
                    fontSize: "80px",
                    color: "#a20000",
                    width: "100%",
                  }}
                >
                  <div className={styles2.loader}>
                    <div className={styles2.spinner}></div>
                  </div>
                </Grid>
              )}
              {!isLoading && httpError && (
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50%",
                    fontSize: "80px",
                    color: "#a20000",
                    width: "100%",
                  }}
                >
                  {httpError}
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default Details0010;
