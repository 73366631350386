import React, { useState } from "react";
import { Box, Grid, Checkbox, TextField, Button } from "@mui/material";
import CustomizedAccordions from "./accordion/Accordion";
import ConfirmationAlert from "../../common/AlertPopUp/ConfirmationAlert";
import httpUpdate from "../../../Services/http/httpUpdate";

const NotificationsInfo = (props) => {
  const notificationsInfo = props.notificationsInfo;
  const [isLoading, setIsLoading] = useState(false);
  const [httpError, setHttpError] = useState("");

  const [myNotifications, setMyNotifications] = useState({
    APCAT1: notificationsInfo?.[0]?.[0]?.APCAT1,
    APCAT2: notificationsInfo?.[0]?.[0]?.APCAT2,
    APCAT3: notificationsInfo?.[0]?.[0]?.APCAT3,
    APCAT4: notificationsInfo?.[0]?.[0]?.APCAT4,
    APCAT5: notificationsInfo?.[0]?.[0]?.APCAT5,
    APLINEID: notificationsInfo?.[0]?.[0]?.APLINEID,
    APNOTIFY: notificationsInfo?.[0]?.[0]?.APNOTIFY,
    APNOTIFYTYPE: notificationsInfo?.[0]?.[0]?.APNOTIFYTYPE,
    APSTATUS: notificationsInfo?.[0]?.[0]?.APSTATUS,
    APSYSTEMID: notificationsInfo?.[0]?.[0]?.APSYSTEMID,
    APUSERID: notificationsInfo?.[0]?.[0]?.APUSERID,
  });

  const [updatedNotifications, setUpdatedNotifications] =
    useState(notificationsInfo);

  const [isEdited, setIsEdited] = useState(false);

  const [emailError, setEmailError] = useState(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const updateState = () => {
    const newState = updatedNotifications?.[0]?.map((obj) => {
      if (obj) {
        return myNotifications;
      }
      return obj;
    });
    updatedNotifications[0] = newState;

    const specificNoti = updatedNotifications?.[0]?.[0];
    httpUpdate.put("dataset", "notificationdatamain", specificNoti).then(
      (response) => {
        console.log("Donee!!!!!!!");
        setIsLoading(false);
      },
      (error) => {
        console.log("error request");
        setHttpError(`${error.message}`);
      }
    );
    setIsEdited(false);
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Grid
        container
        sx={{ display: "flex", alignItems: "center", textAlign: "left" }}
      >
        <Grid item xs={1} sx={{ textAlign: "right" }}>
          <Checkbox
            sx={{ color: "black" }}
            onChange={() =>
              setMyNotifications({
                ...myNotifications,
                APSTATUS: myNotifications.APSTATUS === "A" ? "N" : "A",
              })
            }
            checked={myNotifications.APSTATUS === "A"}
            onClick={() => setIsEdited(true)}
          />
        </Grid>
        <Grid item xs={3.5}>
          Send Notifications By Email
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            sx={{
              display: "flex",
              justifyContent: "left",
            }}
            id="outlined-basic"
            autoComplete="off"
            label="Email"
            variant="outlined"
            value={myNotifications.APNOTIFY}
            onChange={(e) => {
              setMyNotifications({
                ...myNotifications,
                APNOTIFY: e.target.value.trim(),
              });
              if (isValidEmail(e.target.value.trim())) {
                setIsEdited(true);
                setEmailError("");
              } else {
                setIsEdited(false);
              }
            }}
            onBlur={() => {
              if (!isValidEmail(myNotifications.APNOTIFY)) {
                setEmailError("Your Email Is Invalid!");
                setIsEdited(false);
              } else {
                setIsEdited(true);
                setEmailError("");
              }
            }}
          />
        </Grid>
        <Grid item xs={0.5}></Grid>
        <Grid
          item
          xs={1}
          sx={{
            backgroundColor: "#33a1c9",
            borderRadius: "7px",
            textAlign: "center",
          }}
        >
          <Button
            sx={{ color: "white", width: "100%", border: "none" }}
            onClick={handleClickOpen}
            disabled={!isEdited}
          >
            Save
          </Button>
          <ConfirmationAlert
            onClick={updateState}
            description="Are you sure you want to save changes?"
            handleClose={handleClose}
            open={open}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4.5}></Grid>
        <Grid item xs={6}>
          {emailError && (
            <Grid
              sx={{
                color: "#cb1010",
                fontSize: "12px",
                marginTop: "2px",
                fontWeight: "bold",
              }}
            >
              {emailError}
            </Grid>
          )}
        </Grid>
        <Grid item xs={0.5}></Grid>
      </Grid>
      <Grid container>
        <Grid item xs={11}>
          <CustomizedAccordions
            systemName="Generator"
            system="generator"
            expanded="panel1"
            notificationsInfo={notificationsInfo}
          />
        </Grid>
        <Grid item xs={11}>
          <CustomizedAccordions
            systemName="Grid"
            system="grid"
            expanded="none"
            notificationsInfo={notificationsInfo}
          />
        </Grid>
        <Grid item xs={11}>
          <CustomizedAccordions
            systemName="Load"
            system="load"
            expanded="none"
            notificationsInfo={notificationsInfo}
          />
        </Grid>
        <Grid item xs={11}>
          <CustomizedAccordions
            systemName="Inverter"
            system="inverter"
            expanded="none"
            notificationsInfo={notificationsInfo}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotificationsInfo;
